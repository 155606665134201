import styled from 'styled-components'

export const CustomDropListStyle = styled.label`
    .ui.fluid.dropdown, .ui.fluid.dropdown:hover .ui.upward.dropdown>.menu {
        display: block;
        width: 130px;
        font-size:13px;
        border:0;
    }
    .ui.fluid.dropdown>.dropdown.icon {
        // padding-top: 4px;
    }
    .ui.dropdown>.text {
        font-size: 13px;
        text-align: left;
        padding-left: 5px;
    }
    .ui.selection.active.dropdown:hover {
        box-shadow:none;
        font-size:13px;
        border-radius:0;

    }
    .ui.active.visible.fluid.selection.dropdown {
        font-size:13px;
        border-radius:0;
    }
    .ui.dropdown .menu {
        left: 0;        
        position:relative;      
        margin-top: 6%;
    }
    .ui.selection.dropdown {
        border-bottom:1px solid #97a5ad;
        border-radius:0;
    }
    .ui.selection.active.dropdown {
        border-bottom:0px solid #97a5ad;
    }
    .ui.image {
        padding-top: 6%;
    }
    .visible.transition{
        border:1px solid #97a5ad !important;    
    }
    .ui.selection.dropdown .menu, .ui.selection.dropdown .menu:hover {

        border-radius: 6px;
        margin:4px 0 4px 0;
        border:1px solid #97a5ad !important;
    }
    .ui.upward.selection.dropdown.visible {
        border-radius:0 !important;
    }
    .ui.upward.dropdown>.menu, .ui.upward.dropdown>.menu:hover {
        border-radius: 6px;
        margin:16px 0 4px 0;
        border:1px solid #97a5ad !important;
    }
    .ui.dropdown>.dropdown.icon {
        margin: 2px 0 0 1em;
    }        
    .ui.dropdown .menu>.item, .active selected item {
        position: relative;
        cursor: pointer;
        display: block;
        border: none;
        height: auto;
        text-align: left;
        border-top: none;
        line-height: 1em;
        color: rgba(0,0,0,.87);
        padding: .78571429rem 1.14285714rem!important;
        font-size: 13px;
        text-transform: none;
        font-weight: 400;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-touch-callout: none;
    }
    .flag-img {
        width: 23px;
        // height: 23px;
        float: left;
    }  
`