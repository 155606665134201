import React from 'react'

import LoginForm from '../components/loginForm'

const LoginPage = () => (
  <div>
    <LoginForm />
  </div>
)

export default LoginPage
