import React, { useState } from 'react'
import { Query } from 'react-apollo'
import PropTypes from 'prop-types'
import queryString from 'qs'
import { setDefaultLanguage, setLanguage, setTranslations } from 'react-multi-lang'
import config from '../config/app.config'
import { getUserSetting } from '../gql/user.gql'
import withConfiguration from '../hocs/withConfiguration'
import { setLocal } from '../util'
import { isOrganizationToken } from '../gql'

setTranslations(config.SUPPORTED_LANGUAGE)
setDefaultLanguage(config.DEFAULT_LANGUAGE)

const LanguageSetter = ({ children, CURRENCY }) => {
  // set local (from config or fallback to 'en')
  setLocal(config.CURRENCY_TO_LOCALE[CURRENCY] || 'en')
  const [ready, setReady] = useState(false)
  // get lang from url (if existed)
  const langInUrl = queryString.parse(window.location.search, { ignoreQueryPrefix: true }).lang

  /* figure out which language to use now
  * 1.respect language in url query string first
  * 2. if fail => look at local storage
  * 3. if fail => look at configuration
  * */
  let langToUse = langInUrl ?? localStorage.getItem('lang') ?? config.DEFAULT_LANGUAGE

  // check if selected language is available to use
  if (!Object.keys(config.SUPPORTED_LANGUAGE).includes(langToUse)) {
    // if not then change it to default language
    langToUse = config.DEFAULT_LANGUAGE
  }

  // store selected language to local storage
  localStorage.setItem('lang', langToUse)

  const fetchLanguageFile = (userLanguage) => {
    fetch(`${process.env.REACT_APP_UPLOADED_ENDPOINT}/public/translate/${userLanguage}.json`)
      .then(response => response.json())
      .then((languages) => {
        setTranslations({ ...config.SUPPORTED_LANGUAGE, ...languages })
        setLanguage(userLanguage)
        setReady(true)
      })
      .catch((error) => {
        console.error('getting translation file', error)
      })
  }

  if (isOrganizationToken()) {
    return (
      <Query query={getUserSetting} fetchPolicy="cache-first">
        {({ data, loading }) => {
          if (!loading) {
            const userLanguage = data.userSetting?.language.toLowerCase() ?? langToUse
            fetchLanguageFile(userLanguage)
          }
          if (ready) {
            return children
          }
          return null
        }}
      </Query>
    )
  }
  fetchLanguageFile(langToUse)
  return children
}

LanguageSetter.propTypes = {
  children: PropTypes.node.isRequired,
  CURRENCY: PropTypes.string,
}

export default withConfiguration(LanguageSetter, 'CURRENCY')
