import React from 'react'
import { Mutation, Query } from 'react-apollo'
import SignupSelectOrganization from '../components/selectOrganization'
import {
  authenticateToOrganizationMutation, userOrganizationsQuery,
} from '../gql/organization.gql'

const SelectOrganization = () => (
  <Mutation mutation={authenticateToOrganizationMutation}>
    {authenticateToOrganization => (
      <Query query={userOrganizationsQuery}>
        {
          ({ loading, data }) => {
            if (loading) return null
            return (
              <SignupSelectOrganization
                organizations={data.userOrganizations}
                authenticateToOrganization={authenticateToOrganization}
              />
            )
          }
        }
      </Query>
    )}
  </Mutation>
)

export default SelectOrganization

