export const isSystemReady = async (url) => {
  let requestingUrl = new URL(url)
  if(process.env.NODE_ENV === 'development') {
    const serviceUrl = new URL(process.env.REACT_APP_REST_ENDPOINT)
    requestingUrl.port = serviceUrl.port
  }
  return fetch(`${requestingUrl.href}v1/system/health`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (!response.ok) { throw response }
    return response.json()
  }).then(json => json.status === 'ok')
    .catch(() => false)
}
