import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const NoneOrganizationBody = styled.div`
  display: flex;
  flex-direction: column;
  .org-select-title{
    margin: 140px auto 0px auto;
    text-align:center;
    font-size: 23px;
    color: #26292c;
    font-weight: 300;
  }
  .wizboy-image{
      width:65%;
      height:40%;
      max-width:900px;
      max-height:300px;
      margin-top:45px;
      margin-left: auto;
      margin-right: auto;
  }
`

export const CreateOrgButton = styled(Button)`
    min-width: 170px;
    height: 40px;
    margin: 20px auto !important;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(33, 75, 116, 0.15);
    background-color: #495e72 !important;
    .MuiButton-label{
      font-size: 15px;
      font-weight: bold;
      color: #fff;
    }
`
