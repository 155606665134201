import styled from 'styled-components'
import { Button, Checkbox } from '@material-ui/core'
import lockIcon from '../../assets/image/loginSignup/lock-key.svg'

export const SelectOrganizationBody = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 470px;
  margin: 40px auto 0;
  .org-select-title{
    margin: 0 auto 40px;
    font-size: 30px;
  }
  .org-list{
    display:flex;
    flex-direction: column;
  }
`

export const Organization = styled.div`
    margin: auto 0 15px;
    display: flex;
    padding: 10px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    cursor: pointer;
    &.selected{
      box-shadow: 0 0 3px 0 rgb(73 94 114 / 74%);
      border-color: #495e72;
      .select-org{
        border-color: #495e72;
        &:after{
          content: '';
          background: #495e72;
          height: 12px;
          width: 12px;
          position: absolute;
          border-radius: 50%;
          left: 2px;
          top: 2px;
        }
      }
    }
    &.disabled{
      background: #f0f0f0;
      .select-org{
        border: none;
        &:after{
          content: '';
          background-image: url('${lockIcon}');
          display: block;
          width: 24px;
          height: 24px;
          margin-left: -2px;
          margin-top: -1px;
        }
      }
      .select-org-name{
        color: #8e8e8e;
      }
    }
    .select-org{
      width: 20px;
      height: 20px;
      margin: auto 0 auto 5px;
      border: 2px solid #e3e3e3;
      border-radius: 50%;
      position:relative;
    }
    .select-org-name{
      margin: auto 10px auto 15px;
      font-size: 15px;
      width: 220px;
    }
    .select-org-plan{
      margin: auto 10px auto 10px;
      padding: 3px 0;
      text-align: center;
      background: #f2f4f8;
      width: 100px;
      color: #7c8296;
      border-radius: 5px;
      &.free{
        color: #7c8296;
        background: #f2f4f8;
      }
      &.enterprise{
        color: #58b99b
        background-color: #e7f7f3;
      }
      &.startup{
        color: #518fcc
        background-color: #e2ecf5;
      }
    }
    .select-org-days{
      margin: auto 0 auto auto;
      font-size: 11px;
      line-height: 11px;
      color: #7c8296;
    }
`

export const SelectOrgButton = styled(Button)`
    width: 250px;
    height: 40px;
    margin: 20px auto !important;
    box-shadow: 0 1px 2px 0 rgba(0; 0; 0; 0.16) !important;
    background-color: #495e72 !important;
    span{
      font-size: 21px;
      font-weight: bold;
      font-family: Lato;
      text-transform: none;
      color: #fff;
    }
`

export const CheckboxRemebered = styled(Checkbox)`
    color: #518fcc !important;
    &.Mui-checked:hover{
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
    &:hover{
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
`

export default Organization
