import React, { Component } from 'react'
import PropTypes from 'prop-types'
import queryString from 'qs'
import { setLanguage } from 'react-multi-lang'

class OAuthLogin extends Component {
  componentDidMount() {
    const query = queryString.parse(window.location.search, { ignoreQueryPrefix: true })
    const { onError, onSuccess, provider } = this.props
    if (query.state) {
      const passedState = JSON.parse(query.state)
      if (passedState.provider === provider) {
        if (passedState.lang) setLanguage(passedState.lang.toLowerCase())
        if (query.code) {
          onSuccess(query.code, passedState)
        }
        if (query.error) {
          onError(query.error, query.error_description, passedState)
        }
      }
    }
  }

  handleClick = () => {
    const { oAuthUrl } = this.props
    window.location.href = oAuthUrl
  }

  render() {
    const { children, className } = this.props
    return (
      <button type="button" className={className} onClick={this.handleClick}>
        {children}
      </button>
    )
  }
}

OAuthLogin.propTypes = {
  className: PropTypes.string,
  provider: PropTypes.string.isRequired,
  oAuthUrl: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

OAuthLogin.defaultProps = {
  className: null,
}

export default OAuthLogin
