import styled from 'styled-components'

const Wrapper = styled.div`
  margin-right: 5px;
  background-image: url(${props => props.image});
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: ${({ size, sizeType }) => `${size}${sizeType}`};
  height: ${({ size, sizeType }) => `${size}${sizeType}`};
`

export default Wrapper
