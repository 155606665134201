import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const FreeTrailExpriedBody = styled.div`
  display: flex;
  flex-direction: column;
  .logo-wisible{
    width: 48px;
    height: 48px;
    margin: 50px auto 0px auto;
  }
  .org-select-title{
    margin: 40px auto 0px auto;
    text-align:center;
    font-size: 24px;
    color: #26292c;
    font-weight: bold;
  }
  .org-select-sub-title{
    font-size: 15px;
    font-weight: 300;
    margin: 10px auto 0px auto;
    color: #26292c;
    text-align:center;
  }
  .wizboy-image{
      width: 436px;
      height: 310.5px;
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
  }
`


export const UpgradeOrgButton = styled(Button)`
    width: 320px;
    height: 40px;
    margin: 25px auto !important;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(33, 75, 116, 0.15);
    background-color: #495e72 !important;
    .MuiButton-label{
      font-size: 15px;
      font-weight: bold;
      font-family: Lato;
      text-transform: none;
      color: #fff;
    }
`
