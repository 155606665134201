import React, { Component } from 'react'
import { Form, Formik } from 'formik'
import { Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import { translate } from 'react-multi-lang'
import { Grid, InputAdornment } from '@material-ui/core'
import Loading from '../common/loading'
import {
  Card,
  Center,
  ColumnCenter,
  Container,
  CustomButton,
  CustomTextField,
  Error,
  ReCAPTCHASmall,
  Title,
  TitleOrg,
} from './StylesLinkOrganizationForm'
import { Fieldset } from './style'
import { validationSchema } from './ValidationForm'
import { isSystemReady } from '../../util/cname'
import { ReactComponent as NameIcon } from '../../assets/image/user_name.svg'
import { ReactComponent as EmailIcon } from '../../assets/image/email.svg'
import { ReactComponent as PhoneIcon } from '../../assets/image/phone.svg'
import { ReactComponent as Lightbulb } from '../../assets/image/lightbulb.svg'
import { ReactComponent as CompanyNameIcon } from '../../assets/image/company_name.svg'
import emailMask from 'email-mask'
import { tracker } from '../../analytics'

class LinkOrganizationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMS: null,
      loading: false,
      redirect: null,
      isLobot: false,
      firstPromoterID: Cookies.get('_fprom_track') ? Cookies.get('_fprom_track') : null,
    }
  }

  async componentDidMount() {
    const { signupDetail } = this.props
    if (!this.props.fromExistedUser) {
      if (this.isWrongPassword()) {
        if (signupDetail.inviteToken) {
          return this.setState({
            redirect: `/invite/${signupDetail?.organization?.id}/${signupDetail.inviteToken}`,
          })
        }
        return this.setState({
          redirect: '/signup?error=existed-email',
        })
      }
      if (this.isAlreadySignup() && signupDetail.login) {
        this.setState({ loading: true })
        await this.login(signupDetail.login, '/selectOrganization')
        this.setState({ loading: false })
      }
      if (signupDetail) {
        const { googleId } = signupDetail
        if (googleId) {
          this.isLoginWithGoogle()
        }
      }
    }
  }

  getSignupFullInput = (values) => {
    const signUpMethod = localStorage.getItem('signUpMethod') || ''
    const { organizationName, phone, name, email } = values
    tracker.track('Signup (complete)', {
      category: 'App Activity',
      userId: this.props?.signupDetail?.id || 'N/A',
      email: emailMask(email),
      organizationId:
        this.props.signupDetail && this.props.signupDetail.organization
          ? this.props?.signupDetail?.organization.id
          : 'N/A',
      signUpDate: new Date(),
      permissionLevel: 'Admin',
      signUpMethod: signUpMethod,
      invited: 'No',
      role: this.props.signupDetail?.organization ? 'User' : 'Super Admin',
      suspend: "No"
    })
    return {
      userInput: {
        email,
        phone,
        name,
        role: this.props.signupDetail?.organization ? 'user' : 'admin',
        active: true,
        organizationId: this.props.signupDetail?.organization
          ? this.props.signupDetail.organization.id
          : null,
      },
      newOrganization: {
        name: this.props.signupDetail?.organization ? null : organizationName,
      },
      firstPromoterID: this.state.firstPromoterID,
      language: localStorage.getItem('lang'),
      country: localStorage.getItem('country'),
    }
  }

  isAlreadySignup = () => {
    const { signupDetail } = this.props
    return signupDetail && signupDetail.message === 'Already have this user'
  }

  isHaveNoEmail = () => {
    const { signupDetail } = this.props
    return signupDetail && signupDetail.message === 'Email is not provided'
  }

  isWrongPassword = () => {
    const { signupDetail } = this.props
    return signupDetail && signupDetail.message === 'Wrong Password'
  }

  login = async (data, redirectPath) => {
    if (!data.message) {
      localStorage.setItem('token', data.token)
      localStorage.setItem('refreshToken', data.refreshToken)
      redirectPath
        ? this.setState({ redirect: redirectPath })
        : await this.redirectToOrganization(this.props.signupDetail?.organization?.id || undefined)
    } else {
      this.setState({ errorMS: data.message })
    }
  }

  doCheckBackendForRedirection = async (organizationId) => {
    const { authenticateToOrganization } = this.props
    const result = await authenticateToOrganization({
      variables: {
        organizationId,
      },
    })
    if (!result.data.authenticateToOrganization || result.data.authenticateToOrganization.error) {
      setTimeout(() => this.doCheckBackendForRedirection(organizationId), 2000)
    } else {
      const { authKey, appEndpoint } = result.data.authenticateToOrganization
      const url = new URL(process.env.REACT_APP_MAIN_APP_LINK)
      const organizationUrl = `${url.protocol}//${appEndpoint}`
      const fullUrl = `${organizationUrl}/?key=${authKey}`
      await this.performRedirection(organizationUrl, fullUrl)
    }
  }

  performRedirection = async (organizationUrl, fullUrl) => {
    const cNameAvailable = await isSystemReady(organizationUrl)
    if (cNameAvailable) {
      window.location.replace(fullUrl)
    } else {
      setTimeout(() => this.performRedirection(organizationUrl, fullUrl), 1000)
    }
  }

  redirectToOrganization = async (organizationId) => {
    setTimeout(() => this.doCheckBackendForRedirection(organizationId), 20000)
  }

  createSignupFull = async (Formikactions, values, inviteToken) => {
    const isInvite = inviteToken !== undefined
    const signUpMethod = localStorage.getItem('signUpMethod') || ''
    this.setState({ errorMS: null, loading: true })
    const cleanEmail = values.email.toLowerCase()
    // TODO signup with invite code
    const result = await this.mutationSignupFull(
      this.getSignupFullInput({
        ...values,
        email: cleanEmail,
      }),
    )
    if (result.data?.signupV2?.user) {
      tracker.track('Internal User Invited', {
        category: 'App Activity',
        email: emailMask(cleanEmail),
        signUpMethod: signUpMethod,
        invited: isInvite,
        signUpDate: new Date(),
        role: this.props.signupDetail?.organization ? 'User' : 'Super Admin',
        suspend: "No",
        userId: this.props?.signupDetail?.id || 'N/A',
        organizationId:
          this.props.signupDetail && this.props.signupDetail.organization
            ? this.props?.signupDetail?.organization.id
            : 'N/A',
      })
      localStorage.removeItem('signUpMethod')
      await this.login(result.data.signupV2.login)
    } else {
      const errorMS = result.data?.signupV2?.error || result.errors[0].message
      this.setState({ errorMS, loading: false })
    }
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  mutationSignupFull(signupFullInput) {
    return this.props.createSignupFull({
      variables: {
        token: this.props.token,
        createUserInput: signupFullInput,
      },
    })
  }

  renderMessage = () => {
    switch (this.state.errorMS) {
      case 'ERROR_ALREADY_INVITED':
        return 'You are already invited, please click a link in your invite email.'
      case 'EMAIL_IS_REQUIRED':
        return 'email is required.'
      case 'ACCOUNT_SUSPENDED':
        return 'Your account is suspended because number of users more than your company license.'
      default:
        return this.state.errorMS
    }
  }

  redirectToSelectOrganization = () => {
    this.setState({
      redirect: '/selectOrganization?to=select',
    })
  }

  onCickIsNotLobot = () => {
    this.setState({ isLobot: false })
  }

  isLoginWithGoogle = () => {
    this.setState({ isLobot: true })
  }

  linkUserAndOrganizationFromInvite = async ({ name, phone }) => {
    this.setState({ errorMS: null, loading: tracker })
    const { linkUserToOrganization, organizationId, inviteToken } = this.props
    const result = await linkUserToOrganization({
      variables: {
        organizationId,
        userInput: {
          name: name.length > 1 ? name : null,
          phone: phone.length > 1 ? phone : null,
        },
        inviteToken,
      },
    })
    if (result.data.linkUserToOrganization) {
      this.redirectToOrganization(organizationId)
    }
  }

  createNewOrganization = async ({ organizationName }) => {
    this.setState({ errorMS: null, loading: true })
    const { createOrganization } = this.props
    const result = await createOrganization({
      variables: {
        organizationInput: {
          name: organizationName,
        },
      },
    })
    this.redirectToOrganization(result.data.createOrganization.id)
  }

  getInitEmail() {
    const { fromExistedUser, user, signupDetail } = this.props
    if (fromExistedUser) {
      return user.email
    }
    return signupDetail?.email || ''
  }

  getInitOrganization() {
    const { fromExistedUser, organization, signupDetail, inviteToken } = this.props
    if (fromExistedUser && inviteToken) {
      return organization.name
    }
    return signupDetail?.organization?.name || ''
  }

  render() {
    const { loading, isLobot, errorMS, redirect } = this.state
    const { signupDetail, t, fromExistedUser, user, inviteToken, organization, token } = this.props
    if (redirect) return <Redirect to={redirect} />
    if (this.isHaveNoEmail()) return <Redirect to="/signup?error=email-not-provided" />
    // if (this.isAlreadySignup()) {
    //   if (errorMS !== 'ACCOUNT_SUSPENDED') return null
    //   return (
    //     <Error color="info">
    //       {this.renderMessage()}
    //     </Error>
    //   )
    // }
    return (
      <Container>
        <Grid container justifyContent="center" spacing={4}>
          {/* hide text for now (3/1/2022) */}
          {/* <Grid item alignItems="center" direction="column" style={{ display: 'flex' }}>
            <img src={signUpImg} />
            <div className='body-left'>
              <p className='body-left-text'>
                In Wisible, you can have multiple organizations under single login account.
                You can switch organization by clicking ‘Organization Name’ under
                Organization drop down menu
              </p>
            </div>
            {(fromExistedUser && !inviteToken) && (
              <div className="footer-link">
                <SmallLink text="< Select Organization" action={this.redirectToSelectOrganization} />
              </div>
            )}
          </Grid> */}
          <Grid item alignItems="center" style={{ position: 'relative' }}>
            <Card>
              {fromExistedUser && (
                <div className="already-account">
                  <Lightbulb />
                  <span className="already-account-text">You already have a Wisible account</span>
                </div>
              )}
              <ColumnCenter>
                <Title>
                  {t('SignUpForm.FinalStep')} {this.getInitOrganization() && 'in'}
                </Title>
                <TitleOrg>{this.getInitOrganization()}</TitleOrg>
              </ColumnCenter>
              <ColumnCenter>
                <Formik
                  enableReinitialize
                  validationSchema={validationSchema(t)}
                  initialValues={{
                    name: signupDetail?.name || '',
                    email: this.getInitEmail(),
                    phone: signupDetail?.phone || '',
                    organizationName: this.getInitOrganization(),
                  }}
                  onSubmit={(values, actions) => {
                    // existing user wants to create new org
                    if (fromExistedUser && !inviteToken) {
                      this.createNewOrganization(values)
                      // existing user process to invitation link
                    } else if (fromExistedUser && inviteToken) {
                      this.linkUserAndOrganizationFromInvite(values)
                      // new user wants to create new org
                    } else {
                      this.createSignupFull(actions, values, signupDetail?.inviteToken)
                    }
                  }}
                >
                  {({ errors, values, handleChange, handleSubmit, touched }) => (
                    <Loading show={loading}>
                      <Form onSubmit={handleSubmit}>
                        <Fieldset disabled={errorMS === 'ACCOUNT_SUSPENDED'}>
                          <CustomTextField
                            value={values.name}
                            fullWidth
                            onChange={handleChange('name')}
                            placeholder={t('SignUpForm.NamePlaceholder')}
                            error={!!errors.name}
                            helperText={errors.name}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <NameIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <CustomTextField
                            type="email"
                            value={values.email}
                            fullWidth
                            onChange={handleChange('email')}
                            placeholder={t('SignUpForm.EmailPlaceholder')}
                            error={!!signupDetail?.message || !!errors.email}
                            helperText={signupDetail?.message || errors.email}
                            disabled={signupDetail?.email || user.email}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <CustomTextField
                            value={values.phone}
                            fullWidth
                            onChange={handleChange('phone')}
                            placeholder={t('SignUpForm.PhonePlaceholder')}
                            error={!!errors.phone}
                            helperText={errors.phone}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PhoneIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {!this.getInitOrganization() && (
                            <CustomTextField
                              value={values.organizationName}
                              fullWidth
                              onChange={handleChange('organizationName')}
                              disabled={signupDetail?.organization?.name || organization}
                              placeholder={t('SignUpForm.CompanyNamePlaceholder')}
                              error={!!errors.organizationName}
                              helperText={errors.organizationName}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CompanyNameIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                          {isLobot ? (
                            <ReCAPTCHASmall
                              sitekey={process.env.REACT_APP_SITE_KEY}
                              onChange={(resReCAPTCHA) => this.onCickIsNotLobot(resReCAPTCHA)}
                            />
                          ) : (
                            <div />
                          )}
                          {errorMS && (
                            <Error color={errorMS === 'ACCOUNT_SUSPENDED' ? 'info' : 'danger'}>
                              {this.renderMessage()}
                            </Error>
                          )}
                          <Center>
                            <CustomButton
                              onClick={handleSubmit}
                              disabled={loading || isLobot}
                              color="primary"
                              fullWidth
                            >
                              {t('SignUpForm.Done')}
                            </CustomButton>
                          </Center>
                        </Fieldset>
                      </Form>
                    </Loading>
                  )}
                </Formik>
              </ColumnCenter>
            </Card>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default translate(LinkOrganizationForm)
