import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { FreeTrailExpriedBody, UpgradeOrgButton } from './styles'
import WizBoyImage from '../../assets/image/freeTrailExpried/wizboy.png'
import wisibleLogoImg from '../../assets/image/wisible_logo.svg'

const FreeTrailExpried = () => {
    const [redirect, setRedirect] = useState(false)
    const onClickButton = () => {
        setRedirect("/freeTrailExpried")
    }
    if (redirect) return <Redirect to={{ pathname: redirect }} />
    return (
        <FreeTrailExpriedBody>
            <img className="logo-wisible" src={wisibleLogoImg} alt="logo" />
            <h1 className="org-select-title">Fiveloop organization<br />free trial is expired</h1>
            <h1 className='org-select-sub-title'>Upgrade a subscription plan to continue work with Wisible</h1>
            <UpgradeOrgButton onClick={onClickButton}>Upgrade Now</UpgradeOrgButton>
            <img className="wizboy-image" src={WizBoyImage} alt="Wizboy" />
        </FreeTrailExpriedBody>
    )
}


export default FreeTrailExpried
