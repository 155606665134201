import * as yup from 'yup'

export const validationSchema = (t) => yup.object().shape({
  email: yup
    .string()
    .email(t('errors.INVALID_EMAIL'))
    .required(t('errors.REQUIRED_EMAIL')),
  password: yup
    .string()
    .required(t('errors.REQUIRED_PASSWORD'))
    .min(2, t('errors.TOO_SHORT_PASSWORD')),
  confirmPassword: yup
    .string()
    .required(t('errors.REQUIRED_CONFIRM_PASSWORD'))
    .test('passwords-match', t('errors.NOT_MATCH_PASSWORD'), function (value) {
      return this.parent.password === value
    }),
  agreeTermAndPrivacyPolicy: yup
    .boolean()
    .oneOf([true], t('errors.REQUIRED_AGREE_TERM_AND_PRIVACY_POLICY'))
})
