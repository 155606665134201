import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import React, { Component } from 'react'
import { Card, CardBody, CardText, Col, Container, Form, Input, Row } from 'reactstrap'
import '../forgetPassword/ForgetPassword.css'
import wisibleLogo from '../../assets/image/loginSignup/wisible-horizontal.svg'
import { TextField, Button } from './style'
class SignupForm extends Component {
  state = {
    passWord: null,
    rePassWord: null,
    email: null,
    status: null,
    error: null,
    redirect: null,
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleResetPassword = async (e) => {
    e.preventDefault()
    const { passWord, rePassWord } = this.state
    if (passWord === rePassWord) {
      const resetPasswordInput = {
        token: this.props.token,
        newPassword: this.state.passWord,
      }
      const result = await this.props.resetPasswordMutate({ variables: { resetPasswordInput } })
      const { error } = result.data.resetPassword
      if (error) {
        this.setState({ error })
      } else {
        this.setState({ error: null, redirect: '/' })
      }
      // }TH_LANG  else { this.setState({ error: 'คุณพิมพ์รหัสผ่านไม่ตรงกัน' }) }
    } else { this.setState({ error: 'Your passwords are mismatched' }) }
  }

  render() {
    const { error, redirect } = this.state
    const { tokenStatus } = this.props
    if (redirect) {
      return <Redirect to={redirect} />
    }
    return (
      <Container>
        <Row>
          <Col sm={{ size: '6', offset: 3 }}>
            <Card className="signupCard">
              <CardBody className="text-center">
                <a href="/"><img src={wisibleLogo} alt="Wisible" /></a>
                {/* TH_LANG <CardText>สร้างรหัสผ่านใหม่</CardText> */}
                <CardText>Create your new password</CardText>
                {tokenStatus === 'token valid' ?
                  <Form onSubmit={e => this.handleResetPassword(e)}>
                    <TextField
                      value={this.state.passWord}
                      type="passWord"
                      name="passWord"
                      fullWidth
                      onChange={(e) => {
                        this.handleInputChange(e)
                      }}
                      // TH_LANG placeholder="รหัสผ่านใหม่"

                      placeholder="New password"
                    />
                    <TextField
                      value={this.state.rePassWord}
                      type="passWord"
                      name="rePassWord"
                      fullWidth
                      onChange={(e) => {
                        this.handleInputChange(e)
                      }}
                      // TH_LANG placeholder="รหัสผ่านใหม่อีกรอบ"
                      placeholder="Confirm your password"
                    />
                    {error ? <div className="red-text">{error}</div> : null}

                    <Button
                      color="primary"
                      fullWidth
                      type="submit"
                    >
                      {/* TH_LANG เปลี่ยนรหัสผ่าน */}
                      Reset
                    </Button>
                  </Form>
                  :
                  <div className="red-text">
                    {/* TH_LANG <div>ไม่สามารถใช้งานได้ เนื่องจาก link หมดอายุ</div>
                    <div>หรือ คุณไม่ได้ทำรายการตามเวลาที่กำหนด</div> */}
                    <div>We’re sorry! Your session has expired,
                      <a href={`/forgetpassword`}> please try again.</a>
                    </div>
                  </div>
                }

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

SignupForm.propTypes = {
  resetPasswordMutate: PropTypes.func.isRequired,
  loginMutate: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
}

export default SignupForm
