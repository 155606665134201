import styled from "styled-components";
import { makeStyles } from "@material-ui/styles";

export const TwoFAContainer = styled.div`
    .card{
        padding: 30px;
        background: #FFFFFF;
        border: 1px solid #E2E8F2;
        border-radius: 5px;
        width: 570px;
        min-height: 570px;
        margin: 82px auto;
        &.stretched{
            min-height: 740px;
        }
    }
    .header{
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        &>span{
            font-size: 18px;
            color: #98A5BA;
        }
        &>img{
            width: 12px;
            height: 12px;
            cursor: pointer;
        }
    }
    .form{
        display: contents;
    }
    .banner{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 568px;
        height: 210px;
        background-color: rgba(113, 116, 129, 0.05);
        position: inherit;
        top: 0;
        left: -30px;
        &.done{
            background-color: #E8FAFA;
        }
    }
    .body{
        margin-top: 30px;
        .qr-code{
            display: flex;
            justify-content: center;
            margin-top: 48px;
            margin-bottom: 40px;
        }
        .manual-key{
            display: block;
            margin-top: 42px;
            margin-bottom: 40px;
        }
        .secret-box{
            background: #F7F8FA;
            border: 2px solid #FFFFFF;
            border-radius: 5px;
            padding: 28px 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &>.secret-key{
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
            }
            &>.secret-key-icon{
                cursor: pointer;
                position: absolute;
                right: 30px;
            }
        }
        .digits-container{
            grid-gap: 10px;
            margin-top: 55px;
            margin-bottom: 27px;
        }
        .digits-input{
            background: #F7F8FA;
            border: 2px solid #FFFFFF;
            border-radius: 5px;
            width: 55px !important;
            height: 60px;
            font-size: 20px;
        }
        .digits-focus{
            outline: none;
            border: 2px solid #495E72;
        }
        .warning-icon{
            width: 18px;
            height: 18px;
            margin-bottom: 5px;
            margin-right: 8px;
            fill: #C75959;
        }
    }
    .footer-divider{
        margin-top: auto;
        &>hr{
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
    .footer{
        display: flex;
        justify-content: end;
        grid-gap: 32px;
        &>.submit-button{
            min-width: 180px;
        }
    }
`

export const useStyles = makeStyles(() => ({
    textFieldFilled: {
        width: '100%',
        '& .MuiFilledInput-input': {
            padding: '12px 12px 10px',
            height: '18px',
        },
        '& .MuiFormHelperText-contained': {
            margin: 0,
        }
    },
    textField: {
        width: '100%',
        '& .MuiInput-root': {
            padding: '0px 12px',
            height: '40px',
        }
    }
}))