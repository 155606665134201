import React, { useState } from 'react'
import { clearStorage } from '../../util'
import SignupForm from '../signUp/SignupForm'
import LinkOrganizationForm from '../linkOrganizationForm'
import DynamicInviteFormWrapper from './styles'

const DynamicInviteForm = ({ inviteToken, organizationId }) => {
  const [mode, setMode] = useState(localStorage.getItem('token') ? 'existed' : 'new')

  const forceLogout = () => {
    clearStorage()
    window.location.reload()
  }

  return (
    <DynamicInviteFormWrapper>
      {mode === 'new' ? (
        <SignupForm inviteToken={inviteToken} organizationId={parseInt(organizationId, 10)} />
      ) : (
        <LinkOrganizationForm
          fromExistedUser
          inviteToken={inviteToken}
          organizationId={parseInt(organizationId, 10)}
        />
      )}
    </DynamicInviteFormWrapper>
  )
}

export default DynamicInviteForm
