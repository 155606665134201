import React from 'react'
import { Mutation, Query } from 'react-apollo'
import { adopt } from 'react-adopt'

import ResetPassword from './ResetPassword'

import { resetPassword, getTokenStatus } from '../../gql/forgetpassword.gql'

const resetPasswordMutation = ({ render }) => (
  <Mutation mutation={resetPassword}>
    {
      resetPasswordMutate => render({ resetPasswordMutate })
    }
  </Mutation>
)

const getTokenStatusQuery = ({ render, token }) => (
  <Query query={getTokenStatus} variables={{ token }}>
    {
      ({ loading, data, error }) => render({ loading, data, error })
    }
  </Query >
)
const Composed = adopt({
  getTokenStatusQuery,
  resetPasswordMutation,
})

const resetPasswordComposeMutation = props => (
  <Composed {...props}>
    {
      ({ resetPasswordMutation: { resetPasswordMutate }, getTokenStatusQuery: { loading, data, error } }) => {
        if (!loading) {
          return (<ResetPassword resetPasswordMutate={resetPasswordMutate} tokenStatus={data.getTokenStatus.status} {...props} />)
        }
        return null
      }
    }
  </Composed>
)

export default resetPasswordComposeMutation

