import React, { Component } from 'react'
import { tracker } from '../../analytics'

const withTrackPage = (WrappedComponent, eventName, category) =>
  class extends Component {
    componentDidMount() {
      tracker.track(eventName, {
        category,
      })
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

export default withTrackPage
