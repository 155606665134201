import gql from 'graphql-tag'
import { loginFields } from './login.gql'

export const signupEmailMutation = gql`
  mutation createSignupEmail($signupEmailInput : SignupEmailInput){
    createSignupEmail(signupEmailInput: $signupEmailInput){
      email
      error
    }
  }
`

export const createSignupFullMutation = gql`
  mutation createSignupFull($token: String, $createUserInput: CreateUserInput, $firstPromoterID: String){
    createSignupFull(token: $token, createUserInput: $createUserInput, firstPromoterID: $firstPromoterID) {
      id
      user {
        id
      }
      error
    }
  }
`
export const signupWithEmailMutation = gql`
  mutation signupWithEmail($createUserInput: CreateUserInput, $inviteToken: String, $firstPromoterID: String) {
    signupWithEmail(createUserInput: $createUserInput, inviteToken: $inviteToken, firstPromoterID: $firstPromoterID) {
      action
      token
    }
  }
`
export const signupV2Mutation = gql`
  mutation signupV2Mutation($token: String, $createUserInput: CreateUserInput, $firstPromoterID: String){
    signupV2(token: $token, createUserInput: $createUserInput, firstPromoterID: $firstPromoterID) {
      id
      error
      user {
        id
      }
      login ${loginFields}
      appEndpoint
    }
  }
`
export const getSignupDetail = gql`
  query signUpDetail($token: String!){
  signupDetail(token: $token){
    id
    email
    name
    imageUrl
    message
    password
    inviteToken
    organization{
      id
      name
    }
    login ${loginFields}
  }
}
`

export default {}
