import gql from 'graphql-tag'

export const createUserMutation = gql`
  mutation createUser($createUserInput: CreateUserInput){
    createUser(createUserInput: $createUserInput) {
      id
      email
    }
  }
`
export const getCurrentUser = gql`
  query GetCurrentUser {
    currentUser:me {
      id
      user_id:id
      name
      email
      phone
      role
      isAdminSomeBoard
      organization{
        id
        name
        lockedAt
        resetSampleDataAt
        isLocked
      }
      imageUrl
      user_hash:intercomHash
      showOnboarding
      emailSyncState
      createdAt
    }
  }
`
export const getCurrentUserRole = gql`
  query GetCurrentUser {
    currentUser:me {
      id
      role
      isAdminSomeBoard
    }
  }
`

export const getUserAndPipeline = gql`
  query getUserAndPipeline {
    pipelines {
      id
      name
      members {
        id
        role
        user {
          id
        }
      }
    }
    users {
      id
      name
      role
      email
      imageUrl
      active
    }
  }
`
export const getUserFromPipelineId = gql`
  query getUserFromPipelineId($id: Int!) {
    pipeline(id: $id){
      id
      members{
        id
        user {
          id
          name
          role
          email
          imageUrl
          active
        }
      }
    }
  }
`

export const queryAvatarDetail = gql`
  query getUserDetail($userInput: UserInput) {
    user(userInput: $userInput) {
      id
      name
      imageUrl
    }
  }
`

export const queryEmailConnect = gql`
  query isConnectEmail {
    connectEmail
  }
`

export const inviteUserMutation = gql`
  mutation inviteUser($inviteUserInputList: [InviteUserInputList]) {
    inviteUser(inviteUserInputList: $inviteUserInputList) {
      id
      email
      error
      updatedAt
    }
  }
`

export const getInviteLink = gql`
  query getInviteLink {
    invitationLink
  }
`

export const getInvitationUser = gql`
  query invitationUser {
    invitationUser {
      id
      role
      pipelineId
      email
      error
      updatedAt
    }
  }
`

export const cancelInviteUser = gql`
  mutation cancelInviteUser($userInput: UserInput) {
    cancelInviteUser(userInput: $userInput) {
      id
      email
      error
      updatedAt
    }
  }
`

export const userManagement = gql`
  mutation userManagement($userInput:[UserInput]){
    userManagement(userInput:$userInput){
      id
      role
      active
    }
  }
`

export const generateLineOtpMutation = gql`
  mutation generateLineOtp {
    generateLineOtp {
      id
      token
      expiredTime
    }
  }
`
export const getUsers = gql`
  query users{
    users{
      id
      name
      email
      role
      imageUrl
      active
    }
  }
`

export const getZapierApiKey = gql`
  query zapierApiKey {
    zapierApiKey
  }
`

export const generateZapierApiKey = gql`
  mutation generateZapierApiKey {
    generateZapierApiKey
  }
`

export const getUserSetting = gql`
  query userSetting {
    userSetting {
      id
      acceptAutoLinkEmail
      acceptAutoReadEmail
      language
    }
  }
`

export const saveUserSetting = gql`
  mutation updateUserSetting($userSettingInput: UserSettingInput) {
    updateUserSetting(userSettingInput: $userSettingInput) {
      id
      acceptAutoLinkEmail
      acceptAutoReadEmail
      language
    }
  }
`

export const addUserFilterMutation = gql`
  mutation addUserFilter($userFilterInput: UserFilterInput) {
    addUserFilter(userFilterInput: $userFilterInput) {
      id
      name
      filter
      showArchived
    }
  }
`

export const getUserFilters = gql`
  query userFilters {
    userFilters {
      id
      name
      filter
      showArchived
    }
  }
`

export const removeUserFilter = gql`
  mutation removeUserFilter($filterId: Int) {
    removeUserFilter(filterId: $filterId) {
      id
      name
      filter
      showArchived
    }
  }
`

export const linkUserToOrganization = gql`
  mutation linkUserToOrganization($organizationId: Int!, $userInput: UserInput, $inviteToken: String!){
    linkUserToOrganization(organizationId: $organizationId, userInput: $userInput, inviteToken: $inviteToken) {
      id
      name
    }
  }
`
