import React from 'react'

import NoneOrganization from '../components/noneOrganization'

const NoneOrganizationPage = () => (
  <div>
    <NoneOrganization />
  </div>
)

export default NoneOrganizationPage
