import React from 'react'
import Loading from '../components/common/loading'

const LineLogin = () => (
  <div>
    <Loading show inline />
  </div>
)

export default LineLogin
