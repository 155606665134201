import {
  withStyles,
  TextField as TextFieldMui,
  Button as ButtonMui,
} from '@material-ui/core'

export const TextField = withStyles(
  {
    root: {
      margin: '10px 0',
      '& .MuiFormLabel-root': {
        color: '#98a5ba',
      },
      '& .MuiFilledInput-root': {
        backgroundColor: '#f0f3f7',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: '1px solid #e2e8f2'
      },
      '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: 0,
      },
      '&  .MuiFilledInput-input': {
        padding: '14px 12px 14px',
        '&::placeholder': {
          color: '#98a5ba',
          opacity: 1,
        }
      }
    },
  }
)(TextFieldMui)

export const Button = withStyles(
  {
    root: {
      margin: '10px 0',
      height: 48,
      '& .MuiButton-label': {
        fontSize: 16,
        fontWeight: 'bold',
        textTransform: 'none',
      }
    },
  }
)(ButtonMui)
