import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import mixpanelPlugin from '@analytics/mixpanel'
import intercomPlugin from '@analytics/intercom'
// import segmentPlugin from '@analytics/segment'

export const tracker = Analytics({
  app: 'wisible-app',
  plugins: [
    googleTagManager({
      containerId: process.env.REACT_APP_GTM_ID,
    }),
    mixpanelPlugin({
      token: process.env.REACT_APP_MIXPANEL_TOKEN,
    }),
    intercomPlugin({
      appId: process.env.REACT_APP_INTERCOM_APP_ID
    })
    // segmentPlugin({
    //   writeKey: process.env.REACT_APP_ANALYTICS_APP_ID
    // })
  ],
})

export default {}
