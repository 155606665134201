import React from 'react'
import CreateOrganizationForm from '../components/linkOrganizationForm'
import PageWrapper from '../styles/pageWrapper'

const SignupFullPage = ({ match: { params } }) => {
  const { token } = params
  return (
    <PageWrapper>
      <CreateOrganizationForm fromExistedUser={false} token={token} />
    </PageWrapper>
  )
}

export default SignupFullPage
