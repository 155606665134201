import React from 'react'
import wiz from './wizBoy'
import Wrapper from './style'

const WizBoy = props => (
  // eslint-disable-next-line react/no-danger
  <Wrapper {...props} dangerouslySetInnerHTML={{ __html: wiz }} />
)

export default WizBoy
