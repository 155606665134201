import React, { Component } from 'react'
import errorIcon from '../../assets/image/error/error.png'
import './Errors.css'
import { clearStorage } from '../../util'
import { Redirect } from 'react-router-dom'

class Error500 extends Component {
  state = {
    redirect: null,
  }
  clearLocalStorage = () => {
    clearStorage()
    this.setState({ redirect: 'login' })
  }
  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />
    return (
      <div className="error-pages">
        <div className="error-container">
          <img src={errorIcon} alt="Network Error" />
          <div className="error-text">
            <h2>AWWW… Don’t worry</h2>
            <p>It’s just a connection error</p>
            <button onClick={this.clearLocalStorage} className="btn btn-primary error-button">
            Click Here
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Error500
