import * as yup from 'yup'

export const validationSchema = (t) => yup.object().shape({
  name: yup
    .string()
    .required(t('errors.REQUIRED_NAME')),
  email: yup
    .string()
    .email(t('errors.INVALID_EMAIL'))
    .required(t('errors.REQUIRED_EMAIL')),
  organizationName: yup
    .string()
    .required(t('errors.REQUIRED_ORGANIZATION_NAME')),
})
