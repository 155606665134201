import React from 'react'
import { Link } from 'react-router-dom'
import error404Icon from '../../assets/image/error/404.svg'
import './Errors.css'

const Error404 = () => (
  <div className="error-pages">
    <img src={error404Icon} alt="Page not found" />
    <h1>Page Not Found</h1>
    {/* TH_LANG <p>เกิดข้อผิดพลาดทางเทคนิคบางอย่าง<br />
          คุณสามารภกด Refresh ได้ที่บราวเซอร์ของคุณหรือเลือกเมนูอื่นเพื่อทำรายการต่อ
        </p> */}
    <p>Page not found, <br /> <Link to="/">please click here to go back.</Link></p>
  </div>
)

export default Error404
