import React from 'react'
import queryString from 'qs'
import { Redirect } from 'react-router'
import { LinearProgress } from '@material-ui/core'

const RedirectTo = () => {
  const { redirectTo, orgId } = queryString.parse(
    window.location.search, { ignoreQueryPrefix: true },
  )
  if (redirectTo && orgId) {
    window.location.href = `/selectOrganization?to=${
      orgId}&redirectTo=${decodeURIComponent(redirectTo)}`
    return (
      <LinearProgress />
    )
  }
  return (
    <Redirect to="/" />
  )
}

export default RedirectTo
