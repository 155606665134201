import React from 'react'
import { Mutation, Query } from 'react-apollo'
import { adopt } from 'react-adopt'

import PropTypes from 'prop-types'
import LinkOrganizationForm from './linkOrganizationForm'
import { getSignupDetail, signupV2Mutation } from '../../gql/signup.gql'
import { loginMutation } from '../../gql/login.gql'
import { getTokenStatus } from '../../gql/forgetpassword.gql'
import {
  authenticateToOrganizationMutation,
  createOrganizationMutation, organizationQuery,
} from '../../gql/organization.gql'
import { getCurrentUser, linkUserToOrganization } from '../../gql/user.gql'

const createSignupFullMutationGql = ({ render }) => (
  <Mutation mutation={signupV2Mutation}>
    {
      createSignupFullMutate => render({ createSignupFullMutate })
    }
  </Mutation>
)

const authenticateToOrganizationMutationGql = ({ render }) => (
  <Mutation mutation={authenticateToOrganizationMutation}>
    {
      authenticateToOrganizationMutate => render({ authenticateToOrganizationMutate })
    }
  </Mutation>
)

const loginMutationGql = ({ render }) => (
  <Mutation mutation={loginMutation}>
    {
      loginMutate => render({ loginMutate })
    }
  </Mutation>
)

const createOrganizationMutationGql = ({ render }) => (
  <Mutation mutation={createOrganizationMutation}>
    {
      createOrganizationMutate => render({ createOrganizationMutate })
    }
  </Mutation>
)

const getSignupDetailQuery = ({ render, token }) => (
  <Query query={getSignupDetail} variables={{ token }}>
    {
      props => render(props)
    }
  </Query>
)

const getTokenStatusQuery = ({ render, token }) => (
  <Query query={getTokenStatus} variables={{ token }}>
    {
      props => render(props)
    }
  </Query>
)

const currentUserQuery = ({ render }) => (
  <Query query={getCurrentUser}>
    {render}
  </Query>
)

const linkUserToOrganizationMutationGql = ({ render }) => (
  <Mutation mutation={linkUserToOrganization}>
    {
      linkUserToOrganizationMutate => render({ linkUserToOrganizationMutate })
    }
  </Mutation>
)

const getOrganizationQuery = ({ render, organizationId }) => (
  <Query query={organizationQuery} variables={{ id: organizationId }}>
    {
      props => render(props)
    }
  </Query>
)

const Composed = adopt({
  getSignupDetailQuery,
  createSignupFullMutationGql,
  loginMutationGql,
  getTokenStatusQuery,
  authenticateToOrganizationMutationGql,
})

const ExistingUserComposed = adopt({
  createOrganizationMutationGql,
  authenticateToOrganizationMutationGql,
  currentUserQuery,
})

const InvitingComposed = adopt({
  createOrganizationMutationGql,
  authenticateToOrganizationMutationGql,
  currentUserQuery,
  linkUserToOrganizationMutationGql,
  getOrganizationQuery,
})

const LinkOrganizationWithGql = (props) => {
  const { fromExistedUser, inviteToken, organizationId } = props

  // existing user wants to create new org
  if (fromExistedUser && !inviteToken) {
    return (
      <ExistingUserComposed {...props}>
        {
          ({
            currentUserQuery,
            authenticateToOrganizationMutationGql: { authenticateToOrganizationMutate },
            createOrganizationMutationGql: { createOrganizationMutate },
          }) => {
            if (!currentUserQuery.loading) {
              return (
                <LinkOrganizationForm
                  user={currentUserQuery.data.currentUser}
                  createOrganization={createOrganizationMutate}
                  authenticateToOrganization={authenticateToOrganizationMutate}
                  {...props}
                />
              )
            }
            return null
          }
        }
      </ExistingUserComposed>
    )

    // existing user process into invitation link
  } else if (fromExistedUser && inviteToken) {
    return (
      <InvitingComposed {...props}>
        {
          ({
            getOrganizationQuery,
            currentUserQuery,
            linkUserToOrganizationMutationGql: { linkUserToOrganizationMutate },
            authenticateToOrganizationMutationGql: { authenticateToOrganizationMutate },
            createOrganizationMutationGql: { createOrganizationMutate },
          }) => {
            if (!currentUserQuery.loading && !getOrganizationQuery.loading) {
              return (
                <LinkOrganizationForm
                  inviteToken={inviteToken}
                  organizationId={organizationId}
                  organization={getOrganizationQuery.data.organization}
                  user={currentUserQuery.data.currentUser}
                  createOrganization={createOrganizationMutate}
                  authenticateToOrganization={authenticateToOrganizationMutate}
                  linkUserToOrganization={linkUserToOrganizationMutate}
                  {...props}
                />
              )
            }
            return null
          }
        }
      </InvitingComposed>
    )

    // new user wants to create new org
  } else {
    return (
      <Composed {...props}>
        {
          ({
            createSignupFullMutationGql: { createSignupFullMutate },
            authenticateToOrganizationMutationGql: { authenticateToOrganizationMutate },
            loginMutationGql: { loginMutate },
            getSignupDetailQuery,
            getTokenStatusQuery,
          }) => {
            if (!getSignupDetailQuery.loading && !getTokenStatusQuery.loading) {
              return (
                <LinkOrganizationForm
                  token={props.token}
                  loginMutation={loginMutate}
                  createSignupFull={createSignupFullMutate}
                  authenticateToOrganization={authenticateToOrganizationMutate}
                  signupDetail={getSignupDetailQuery.data && getSignupDetailQuery.data.signupDetail}
                  tokenStatus={getTokenStatusQuery.data.getTokenStatus.status}
                  {...props}
                />)
            }
            return null
          }
        }
      </Composed>
    )
  }
}

LinkOrganizationWithGql.propTypes = {
  token: PropTypes.string,
  inviteToken: PropTypes.string,
  organizationId: PropTypes.number,
  fromExistedUser: PropTypes.bool.isRequired,
}

LinkOrganizationWithGql.defaultProps = {
  token: '',
  inviteToken: undefined,
}
export default LinkOrganizationWithGql

