import styled from 'styled-components'
import GoogleLoginWithGql from '../../common/googleLogin'
import LineLoginWithGql from '../../oauth/line/LineLogin'
import {
  withStyles,
  TextField,
  Button,
  FormHelperText,
  FormControl,
} from '@material-ui/core'

const colors = {
  BLACK: '#495e72',
  LIGHT_GRAY: '#98a5ba',
  GRAY: '#666666',
  SILVER: '#bebbbb',
  DUST: '#e5e5e5',
  WHITE: '#ffffff',
  WHITE_GRAY: '#e3e3e3',
  RED: '#a31e1e'
}
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const ColumnCenter = styled(Column)`
  justify-content: center;
  align-items: center;
`
export const SubTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  color: ${colors.BLACK};
  margin-bottom: 5px;
  margin-top: 20px;
`

export const Text = styled.p`
  font-size: 18px;
  font-weight: normal;
  line-height: 1.22;
  color: ${colors.LIGHT_GRAY};
  margin-top: 5px;
  margin-bottom: 30px;
`
const ButtonGoogle = `
  height: 48px;
  width: 100%;
  max-width: 400px;
  background: white;
  color: ${colors.BLACK};
  border-radius: 4px;
  white-space: nowrap;
  border: 1px solid ${colors.SILVER};
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
  cursor: pointer;
  margin-bottom: 20px;
  &:focus,
  &:hover {
    border: 1px solid ${colors.BLACK};
    hr{
      background-color: ${colors.BLACK};
    }
  }
  & hr{
    background-color: ${colors.SILVER};
  }
  &:active {
    background-color: ${colors.DUST};
    box-shadow: none;
    transition-duration: 10ms;
  }
`

export const ButtonGoogleLogin = styled(GoogleLoginWithGql)`
  ${ButtonGoogle}
`
export const ButtonLine = styled(LineLoginWithGql)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  ${ButtonGoogle}
`

export const GoogleIconBox = styled.span`
  display: initial;
  vertical-align: middle;
  width: 48px;
  height: 46px;
  box-sizing: border-box;
  svg {
    height: 100%;
    display: inline-block;
  }
`
export const ButtonText = styled.span`
  display: inline-block;
  vertical-align: middle;
  padding: 0 59px;
  font-weight: normal;
  font-size: 18px;
`

export const Divider = styled.h6`
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${colors.LIGHT_GRAY};
  margin: 20px auto;
  font-size: 15px;
  font-weight: normal;
  &:before,
  &:after {
    content: '';
    border-top: 1px solid #e2e8f2;
    margin: 0 20px 0 0;
    flex: 1 0 20px;
  }

  &:after {
    margin: 0 0 0 20px;
  }
`

export const fontInput = `
    font-size: 13px;
    font-weight: 300;
    line-height: 1.23;
    color: ${colors.BLACK};
`
export const Input = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px ${colors.WHITE_GRAY};
  background-color: ${colors.WHITE};
  padding-left: 5px;
  ${fontInput}
  ${props => console.log(props.error)}
  ${props =>
    props.touched && props.error
      ? `border: solid 1px red`
      : `border: solid 1px ${colors.WHITE_GRAY}`}
`
export const CustomTextField = withStyles(
  {
    root: {
      width: 400,
      margin: '10px 0',
      '& .MuiFormLabel-root': {
        color: '#98a5ba',
      },
      '& .MuiFilledInput-root': {
        backgroundColor: '#f0f3f7',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: '1px solid #e2e8f2'
      },
      '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: 0,
      },
      '&  .MuiFilledInput-input': {
        padding: '14px 12px 14px',
        '&::placeholder': {
          color: '#98a5ba',
          opacity: 1,
        }
      }
    },
  }
)(TextField)

export const CustomButton = withStyles(
  {
    root: {
      margin: '10px 0',
      height: 48,
      width: 400,
      '& .MuiButton-label': {
        fontSize: 16,
        fontWeight: 'bold',
        textTransform: 'none',
      }
    },
  }
)(Button)


export const SignUp = styled(Button)`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  margin-top: 20px;
  margin-bottom: 40px;
  &.btn.btn-primary {
    width: 250px;
    height: 40px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const WarningContainer = styled.div`
  width: 400px;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
`

export const WarningText = styled.span`
  ${fontInput}
  color:${colors.RED};
  margin-left: 5px;
`

export const WarningImg = styled.img`
  width: 18px;
  height: 15px;
  margin-right: 5px;
`

export const FooterText = styled.p`
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
`
export const MarginRight = styled.div`
  margin-right: 7px;
`
export const MarginLeft = styled.div`
  margin-left: 7px;
`
export const Left = styled.div`
  /* margin-left: 20px; */
`
export const LineLogo = styled.img`
  width: 35px;
  height: 35px;
`
export const EmailResentText = styled.button`
  color: var(--primary);
  cursor: pointer;
  background: none;
  border: none;
`

export const ConfirmSent = styled.div`
  padding-bottom: 20px;
`

export const CountryDropdownContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 35px;
  right: 50px;
`

export const CustomFormHelperText = withStyles(
  {
    root: {
      margin: '-10px 14px 0',
    },
  }
)(FormHelperText)

export const CustomFormControl = withStyles(
  {
    root: {
      width: 400,
    },
  }
)(FormControl)

export const TermAndPrivacyPolicyContainer = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: inline-flex;
  gap: 4px;
  align-items: baseline;
  &>span{
    color: #98A5BA;
  }
  &>a{
    color: #495E72;;
  }
`