export default `
  <svg viewBox="0 0 517 517" xmlns:xlink="http://www.w3.org/1999/xlink"
       xmlns="http://www.w3.org/2000/svg"
       style="height: 100%; width: 100%; background: rgba(255, 255, 255, 0);" width="202px"
       height="202px">
    <g style="transform-origin: 50% 50%; transform: scale(0.8);">
      <g style="transform-origin: 258.5px 258.5px;"> <defs>
          <linearGradient gradientUnits="userSpaceOnUse" gradientTransform="translate(151.47)"
                          y2="400.49"
                          x2="15.13" y1="352" x1="15.13" id="wizboy-linear-gradient">
            <stop stop-color="#fff" offset="0"
                  style="stop-color: rgb(255, 255, 255); fill: rgb(0, 0, 0);"></stop>
            <stop stop-color="#709cc1" offset="1"
                  style="stop-color: rgb(112, 156, 193); fill: rgb(0, 0, 0);"></stop>
          </linearGradient>
          <linearGradient xlink:href="#wizboy-linear-gradient" y2="409.54" x2="59.34" y1="409.54"
                          x1="-29.09"
                          id="wizboy-linear-gradient-2"></linearGradient>
          <linearGradient xlink:href="#wizboy-linear-gradient"
                          gradientTransform="matrix(-1, 0, 0, 1, 4862.59, 0)"
                          y2="400.49" x2="4509.88" y1="352" x1="4509.88"
                          id="wizboy-linear-gradient-3"></linearGradient>
          <linearGradient xlink:href="#wizboy-linear-gradient"
                          gradientTransform="matrix(1, 0, 0, -1, -4157.18, 819.08)"
                          y2="409.54" x2="4554.1" y1="409.54" x1="4465.67"
                          id="wizboy-linear-gradient-4"></linearGradient>
          <linearGradient gradientUnits="userSpaceOnUse" y2="142.97" x2="140.14" y1="288.03"
                          x1="385.77"
                          id="wizboy-linear-gradient-5">
            <stop stop-color="#bdced6" offset="0"
                  style="stop-color: '#bdced6'; fill: rgb(0, 0, 0);"></stop>
            <stop stop-color="#bdced6" offset="1"
                  style="stop-color: '#bdced6'; fill: rgb(0, 0, 0);"></stop>
          </linearGradient>
        </defs>
        <title>Wiz boy</title>
        <g>
          <g> <g style="isolation:isolate">
              <g>
                <g> <g data-name="Layer 1" id="Layer_1">
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.307081s infinite normal forwards running bounce-01;">
                        <path fill="#fff"
                              d="M210.81,379.34v23.78H122.39V379.34a44.21,44.21,0,0,1,88.42,0Z"
                              style="fill: rgb(255, 255, 255);"></path>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.325145s infinite normal forwards running bounce-01;">
                        <path style="mix-blend-mode:multiply" fill="url(#wizboy-linear-gradient)"
                              opacity="0.46"
                              d="M210.81,379.34v23.78H122.39V379.34a43.87,43.87,0,0,1,6.45-23c15.15,34.69,69.19,20.74,71.74-5.27A43.77,43.77,0,0,1,210.81,379.34Z"></path>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.343208s infinite normal forwards running bounce-01;">
                        <rect fill="#b3cbe8" height="12.84" width="88.42" y="403.12"
                              x="122.39"
                              style="fill: rgb(179, 203, 232);"></rect>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.361272s infinite normal forwards running bounce-01;">
                        <rect style="mix-blend-mode:multiply" fill="url(#wizboy-linear-gradient-2)"
                              height="12.84"
                              width="88.42" y="403.12" x="122.39"></rect>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.379335s infinite normal forwards running bounce-01;">
                        <path fill="#fff"
                              d="M308.49,379.34v23.78h88.42V379.34a44.21,44.21,0,0,0-88.42,0Z"
                              style="fill: rgb(255, 255, 255);"></path>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.397399s infinite normal forwards running bounce-01;">
                        <path style="mix-blend-mode:multiply" fill="url(#wizboy-linear-gradient-3)"
                              opacity="0.46"
                              d="M308.49,379.34v23.78h88.42V379.34a43.87,43.87,0,0,0-6.45-23c-15.14,34.69-69.19,20.74-71.74-5.27A43.77,43.77,0,0,0,308.49,379.34Z"></path>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.415462s infinite normal forwards running bounce-01;">
                        <rect fill="#b3cbe8" transform="translate(705.41 819.08) rotate(180)"
                              height="12.84"
                              width="88.42" y="403.12" x="308.49"
                              style="fill: rgb(179, 203, 232);"></rect>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.433526s infinite normal forwards running bounce-01;">
                        <rect style="mix-blend-mode:multiply" fill="url(#wizboy-linear-gradient-4)"
                              transform="translate(705.41 819.08) rotate(180)" height="12.84"
                              width="88.42"
                              y="403.12" x="308.49"></rect>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.45159s infinite normal forwards running bounce-01;">
                        <rect fill="#c5d0dd"
                              transform="translate(-27.78 14.46) rotate(-10.09)" rx="1.76"
                              height="127.52" width="11.75" y="100.8" x="62.15"
                              style="fill: rgb(197, 208, 221);"></rect>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.469653s infinite normal forwards running bounce-01;">
                        <rect fill="#b3cbe8"
                              transform="translate(-38.06 18.79) rotate(-10.09)" rx="8.9"
                              height="137.59" width="73.83" y="156.15" x="50.48"
                              style="fill: rgb(179, 203, 232);"></rect>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.487717s infinite normal forwards running bounce-01;">
                        <rect fill="#b3cbe8"
                              transform="matrix(-0.98, -0.17, 0.17, -0.98, 814.13, 519.88)"
                              rx="8.9" height="137.59" width="73.83" y="155.72" x="392.77"
                              style="fill: rgb(179, 203, 232);"></rect>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.50578s infinite normal forwards running bounce-01;">
                        <path fill="#fff"
                              d="M422.58,291.76c-1.32,7.54-16.14,15.14-33.1,16.49a2022.28,2022.28,0,0,1-258.2.62c-16.94-1.27-31.81-8.79-33.17-16.34Q86.29,227,74,161.48c-1.34-7.07,11.17-30.29,28-31.93a1941.31,1941.31,0,0,1,316-.76c16.85,1.56,29.46,24.72,28.16,31.8Q434.07,226.16,422.58,291.76Z"
                              style="fill: rgb(255, 255, 255);"></path>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.523844s infinite normal forwards running bounce-01;">
                        <path fill="url(#wizboy-linear-gradient-5)"
                              d="M392.05,271.55c-1.08,5.5-13.12,11-26.89,12a1828.84,1828.84,0,0,1-209.68.5c-13.76-.92-25.83-6.39-26.93-11.89Q119,224.51,109,176.83c-1.09-5.15,9.07-22.05,22.75-23.25A1762.12,1762.12,0,0,1,388.3,153c13.69,1.14,23.93,18,22.86,23.15Q401.4,223.83,392.05,271.55Z"></path>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.541908s infinite normal forwards running bounce-01;">
                        <path fill="#fff"
                              d="M208.41,203.44c0,12.6-5.39,22.83-12.11,22.85s-12.2-10.19-12.23-22.79,5.4-22.83,12.12-22.85S208.38,190.84,208.41,203.44Z"
                              style="fill: rgb(255, 255, 255);"></path>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.559971s infinite normal forwards running bounce-01;">
                        <path fill="#fff"
                              d="M336.19,203.14c0,12.6-5.39,22.83-12.11,22.84s-12.2-10.18-12.23-22.78,5.4-22.84,12.12-22.85S336.16,190.53,336.19,203.14Z"
                              style="fill: rgb(255, 255, 255);"></path>
                      </g>
                    </g>
                    <g>
                      <g style="transform-origin: 258.5px 258.5px; transform: matrix(1, 0, 0, 1, 0, 0); animation: 0.578035s linear -0.578035s infinite normal forwards running bounce-01;">
                        <path opacity="0.28" fill="#b3cbe8"
                              d="M422.58,291.76q11.51-65.61,23.52-131.17c1.3-7.08-11.31-30.24-28.16-31.8-27.31-2.1-54.11-3.65-80.65-4.66,85.15,12.11,78.48,143.82-17.37,161.55S89.83,246.94,89.83,246.94l-.1.13c2.78,15.15,5.65,30.31,8.38,45.46,1.36,7.55,16.23,15.07,33.17,16.34a2022.28,2022.28,0,0,0,258.2-.62C406.44,306.9,421.26,299.3,422.58,291.76Z"
                              style="fill: rgb(179, 203, 232);"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
`
