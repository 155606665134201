import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: '#495e72',
            // light: '#7986cb',
            // dark: '#303f9f',
            // contrastText: '#fff',
        },
        secondary: {
            main: '#98a5ba',
            // light: '#ff4081',
            // dark: '#c51162',
            // contrastText: '#fff',
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        action: {
            disabledBackground: '#F2F4F7;',
            disabled: '#98A5BA',
        }
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: 16,
                textTransform: 'none',
            },
            contained: {
                backgroundColor: '#495e72',
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#495e72',
                }
            },
            text: {
                color: '#495e72',
            }
        },
    },
    props: {
        MuiTextField: {
            color: 'primary',
            variant: 'filled'
        },
        MuiCheckbox: {
            color: 'primary',
        },
        MuiButton: {
            variant: 'contained'
        },
    },
})

export default theme