import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Container, Form } from 'reactstrap'
import { isMobile } from 'react-device-detect'
import { translate } from 'react-multi-lang'
import qs from 'qs'

import { Redirect } from 'react-router'
import { Divider as DividerMui, Grid, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core'
import { tracker } from '../../analytics'
import GoogleIcon from '../common/icon/GoogleIcon'
import {
  ButtonGoogleLogin,
  ButtonLine,
  ButtonText,
  CustomButton,
  CustomTextField,
  GoogleIconBox,
  SignInContainer,
} from './styles'

import TwoFALogin from './twoFA'
import CountryDropdown from '../common/countryDropdown'

import warningImg from '../../assets/image/warning.svg'
import wisibleLogoImg from '../../assets/image/wisible_logo.svg'
import { ReactComponent as EyeIcon } from '../../assets/image/eye.svg'
import { ReactComponent as CloseEyeIcon } from '../../assets/image/close-eye.svg'
import { ReactComponent as EmailIcon } from '../../assets/image/email.svg'
import { ReactComponent as PasswordIcon } from '../../assets/image/password.svg'
import { ReactComponent as LineIcon } from '../../assets/image/line.svg'
import emailMask from 'email-mask'
const getRedirectWithApiKeyFromUrl = () =>
  qs.parse(window.location.search, { ignoreQueryPrefix: true })?.redirectWithApiKey

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      password: null,
      showPassword: false,
      loggedIn: false,
      message: '',
      pipelineId: null,
      isTwoFactorEnabled: false,
      userId: 0,
      loginInputData: null,
      redirectWithApiKey: null,
    }
  }

  componentDidMount() {
    this.splitTextWarning()
  }

  onSubmitLogin = (e) => {
    if (e) e.preventDefault()
    return this.login({ email: this.state.email, password: this.state.password })
  }

  getGoogleAuthToken = (response) => {
    if (response.error) {
      console.error('fail to login with google', response.error)
    }
    const authGoogle = response.getAuthResponse().id_token
    this.setState({ email: null, password: null }, () => this.login({ authGoogle }))
  }

  alertMessage = () => (
    <div className="margin-horizontal absolute-top ">
      <Alert color="danger" className="font-alert">
        <div className="row-alert">
          <div className="margin-right-warning ">
            <img alt="warning message" src={warningImg} className="logo-warning" />
          </div>
          <div className="margin-left-warning">{this.splitTextWarning()}</div>
        </div>
      </Alert>
    </div>
  )

  login = async ({ authGoogle, authLine, email, password, state = {} }) => {
    const redirectWithApiKey = getRedirectWithApiKeyFromUrl() || state.redirectWithApiKey
    if (redirectWithApiKey) {
      this.setState({ redirectWithApiKey })
    }
    const loginInput = {
      email,
      password,
      authGoogle,
      authLine,
    }
    const result = await this.props.loginMutation({
      variables: { loginInput },
    })
    if (!result.errors) {
      if (
        [
          'INVALID_EMAIL_OR_PASSWORD',
          'EMAIL_NOT_REGISTERED',
          'PLEASE_SIGN_IN_WITH_GOOGLE',
          'PLEASE_SIGN_IN_WITH_LINE',
        ].includes(result?.data?.login?.message)
      ) {
        this.setState({ message: result?.data?.login?.message })
      } else if (result.data.login.isTwoFactorEnabled) {
        this.setState({
          isTwoFactorEnabled: true,
          userId: result.data.login.user.id,
          loginInputData: { ...loginInput, login2FA: false },
        })
      } else {
        tracker.track('Login Completed', {
          category: 'App Activity',
          userId: result?.data?.login?.user?.id,
          email: emailMask(result?.data?.login?.user?.email),
          loginMethod: authGoogle ? 'Google' : authLine ? 'Line' : 'Email',
        })
        this.loginSuccess(result)
      }
    }
  }

  loginSuccess = (result) => {
    localStorage.setItem('token', result.data.login.token)
    localStorage.setItem('refreshToken', result.data.login.refreshToken)
    this.props.intercom.hardShutdown()
    this.setState({ loggedIn: true, pipelineId: result.data.login.pipelineId, message: null })
    const { redirectWithApiKey } = this.state
    window.location = `/selectOrganization?${qs.stringify({ redirectWithApiKey })}`
  }

  splitTextWarning = () => {
    const { message } = this.state
    const { t } = this.props
    const warningArr = message.split('.').filter((warningNew) => warningNew !== '')
    const createText = warningArr.map((element) => (
      <div>
        <p>{t(`errors.${element}`)}.</p>
      </div>
    ))

    return createText
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value, message: null })
  }

  handleCancelTwoFA = () => {
    this.setState({ isTwoFactorEnabled: false })
  }

  render() {
    const { loggedIn, message, email, password, pipelineId, isTwoFactorEnabled, userId } =
      this.state
    const { t } = this.props
    if (loggedIn) {
      return <Redirect to={`/deal/${pipelineId}`} />
    }
    if (isTwoFactorEnabled) {
      return (
        <TwoFALogin
          userId={userId}
          loginSuccess={this.loginSuccess}
          handleOnCancel={this.handleCancelTwoFA}
        />
      )
    }
    return (
      <SignInContainer>
        <Container>
          {isMobile && (
            <Alert color="danger">
              {/* TH_LANG เพื่อการใช้งานอย่างมีประสิทธิภาพสูงสุด กรุณาเข้าใช้งานผ่านคอมพิวเตอร์ค่ะ ขออภัยในความไม่สะดวก */}
              Suggestion: Please use desktop for the best viewing experience.
            </Alert>
          )}
          {message ? this.alertMessage() : <div />}
          <div className="absolute-top-right">
            <CountryDropdown
              onChange={(value) => {
                window.location.href = `?lang=${value}`
              }}
            />
          </div>

          <div className="center-column top">
            <img src={wisibleLogoImg} />
            <div className="signIn-container">
              <h2 className="font-signIn">{t('SignIn.SignInToWisible')}</h2>
            </div>
            <Form onSubmit={this.onSubmitLogin} className="center-column">
              {/* <FormGroup> */}
              {/* <div className="input-container"> */}
              <CustomTextField
                name="email"
                type="email"
                placeholder={t('SignIn.EmailPlaceholder')}
                value={email}
                onChange={(event) => this.handleInputChange(event)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* </div> */}
              {/* </FormGroup> */}
              {/* <FormGroup> */}
              {/* <div className="input-container"> */}
              <CustomTextField
                onCopy={(e)=>{
                  e.preventDefault()
                  return false}}
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                placeholder={t('SignIn.PasswordPlaceholder')}
                value={password}
                onChange={(event) => this.handleInputChange(event)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <FormControlLabel
                        style={{margin: '0'}}
                        control={
                          <Checkbox
                            checked={this.state.showPassword}
                            // error={!!errors.agreeTermAndPrivacyPolicy}
                            onChange={() => this.setState({showPassword: !this.state.showPassword})}
                            hidden={true}
                          />}
                        label={this.state.showPassword ? <EyeIcon/> : <CloseEyeIcon/>}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {/* </div> */}
              {/* </FormGroup> */}
              <CustomButton type="submit" color="primary">
                {t('SignIn.SignInButton')}
              </CustomButton>
            </Form>
            <h6 className="signupDeviderdashed">{t('SignIn.Or')}</h6>
            <ButtonGoogleLogin
              action="login"
              redirectWithApiKey={getRedirectWithApiKeyFromUrl()}
              onSuccess={(code, state) => {
                this.login({ authGoogle: code, state })
              }}
              onError={(error, errorDesc) => {
                this.setState({ message: errorDesc || error })
              }}
            >
              <Grid container alignItems="center">
                <GoogleIconBox>
                  <GoogleIcon />
                </GoogleIconBox>
                <DividerMui orientation="vertical" flexItem />
                <ButtonText>{t('SignIn.SignInWithGoogle')}</ButtonText>
              </Grid>
            </ButtonGoogleLogin>
            <ButtonLine
              action="login"
              redirectWithApiKey={getRedirectWithApiKeyFromUrl()}
              onSuccess={(code, state) => this.login({ authLine: code, state })}
              onError={(error, errorDesc) => {
                this.setState({ message: errorDesc || error })
              }}
            >
              <Grid container alignItems="center">
                <GoogleIconBox>
                  <LineIcon />
                </GoogleIconBox>
                <DividerMui orientation="vertical" flexItem />
                <ButtonText>{t('SignIn.SignInWithLine')}</ButtonText>
              </Grid>
            </ButtonLine>
            <div className="margin-top-forgot-password">
              {/* <div className="margin-right-forgot-password "> */}
              <Link to="/forgetpassword">
                <p className="text">{t('SignIn.ForgotPassword')}</p>
              </Link>
              {/* </div> */}
              {/* <div className="center-row ">
                <div className="margin-right-notNum ">
                  <p className="text">{t('SignIn.NotAMember')}</p>
                </div>
                <div className="margin-left-signUp">
                  <Link to="/signup">
                    <p className="text">{t('SignIn.SignUp')}</p>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </Container>
      </SignInContainer>
    )
  }
}

LoginForm.propTypes = {
  loginMutation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({
    login: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
}

LoginForm.defaultProps = {
  data: {
    login: {
      message: '',
    },
  },
}

export default translate(LoginForm)
