import styled from 'styled-components'

const Wrapper = styled.div`
@keyframes bounce-01 {
  0% {
    animation-timing-function: cubic-bezier(0.1361,0.2514,0.2175,0.8786);
    transform: translate(0, 0px) scaleY(1);
  }
  37% {
    animation-timing-function: cubic-bezier(0.7674,0.1844,0.8382,0.7157);
    transform: translate(0, -39.96px) scaleY(1);
  }
  72% {
    animation-timing-function: cubic-bezier(0.1118,0.2149,0.2172,0.941);
    transform: translate(0, 0px) scaleY(1);
  }
  87% {
    animation-timing-function: cubic-bezier(0.7494,0.2259,0.8209,0.6963);
    transform: translate(0, 19.9px) scaleY(0.602);
  }
  100% {
    transform: translate(0, 0px) scaleY(1);
  }
}
`
export default Wrapper
