import { Mutation } from 'react-apollo'
import { useIntercom } from 'react-use-intercom'
import React from 'react'

import LoginForm from './LoginForm'

import { loginMutation } from '../../gql/login.gql'

const LoginWithGraphql = () => {
  const { hardShutdown } = useIntercom()
return(

  <Mutation mutation={loginMutation} fetchPolicy='no-cache'>
    {
      (login, { data }) => (
        <LoginForm loginMutation={login} data={data} intercom={{hardShutdown}} />
      )
    }
  </Mutation>
)}

export default LoginWithGraphql
