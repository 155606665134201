import React from 'react'
import { ApolloConsumer, Mutation } from 'react-apollo'

import SignupFormNew from './SignupForm'
import { signupWithEmailMutation } from '../../../gql/signup.gql'
import { loginMutation } from '../../../gql/login.gql'

const SignupFormNewWithGql = ({ inviteToken, organizationId }) => (
  <ApolloConsumer>
    {client => (
      <Mutation mutation={signupWithEmailMutation}>
        {signupEmail => (
          <Mutation mutation={loginMutation}>
            {
              loginMutate => (
                <SignupFormNew
                  loginMutation={loginMutate}
                  signupEmailMutation={signupEmail}
                  query={client.query}
                  inviteToken={inviteToken}
                  organizationId={organizationId}
                />
              )
            }
          </Mutation>
        )}
      </Mutation>
    )}
  </ApolloConsumer>
)

export default SignupFormNewWithGql
