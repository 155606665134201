import styled from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha'
import { Alert } from 'reactstrap'
import {
  withStyles,
  TextField,
  Button,
} from '@material-ui/core' 

const colors = {
  BLACK: '#495e72',
  LIGHT_GRAY: '#c7c7c7',
  GRAY: '#666666',
  SILVER: '#bebbbb',
  DUST: '#e5e5e5',
  WHITE: '#ffffff',
  WHITE_GRAY: '#e3e3e3',
  RED: '#a31e1e'
}

export const Container = styled.div`
  margin-top: calc(10%);

  .body-left{
    width: 466px;
    margin-top: 19px;
    .body-left-text{
      font-weight: 300;
      font-size: 15px;
      color: #26292c;
    }
  }
  .already-account{
    position: absolute;
    top: -35px;
    width: 440px;
    text-align: center;
    .already-account-text{
      margin-left: 8px;
      font-size: 15px;
      color: #495e72;
      vertical-align: bottom;
    }
  }
  .footer-link {
    width: 466px;
  }
`
export const Title = styled.p`
  font-size: 15px;
  font-weight: normal;
  line-height: 1.2;
  color: ${colors.BLACK};
`
export const TitleOrg = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  color: ${colors.BLACK};
`
export const Card = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px #e2e8f2;
  background-color: ${colors.WHITE};
  padding: 20px 30px;
`
export const Line = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-bottom: solid 1px ${colors.WHITE_GRAY};
  width: 400px;
  margin-top: 10px;
  margin-bottom: 10px;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const ColumnCenter = styled(Column)`
  align-items: center;
  justify-content: center;
`
export const ReCAPTCHASmall = styled(ReCAPTCHA)`
  transform: scale(0.77);
  -webkit-transform: scal(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
`
export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`
export const MarginLeft = styled.div`
  margin-left: 50px;
`
export const Error = styled(Alert)`
  margin: auto;
  width: 400px;
`
export const CustomTextField = withStyles(
  {
    root: {
      margin: '10px 0',
      '& .MuiFormLabel-root': {
        color: '#98a5ba',
      },
      '& .MuiFilledInput-root': {
        backgroundColor: '#f0f3f7',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: '1px solid #e2e8f2'
      },
      '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: 0,
      },
      '&  .MuiFilledInput-input': {
        padding: '14px 12px 14px',
        '&::placeholder': {
          color: '#98a5ba',
          opacity: 1,
        }
      }
    },
  }
)(TextField)

export const CustomButton = withStyles(
  {
    root: {
      height: 48,
      '& .MuiButton-label': {
        fontSize: 16,
        fontWeight: 'bold',
        textTransform: 'none',
      }
    },
  }
)(Button)