import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { CustomDropListStyle } from './style'
import EnglishIcon from '../../../assets/image/country/english.svg'
import IndonesianIcon from '../../../assets/image/country/indonesian.svg'
import ThaiIcon from '../../../assets/image/country/thai.svg'

const options = [
  {
    key: 'English',
    text: 'English',
    value: 'en',
    image: EnglishIcon,
  },
  {
    key: 'Indonesian',
    text: 'Indonesian',
    value: 'id',
    image: IndonesianIcon,
  },
  {
    key: 'Thai',
    text: 'ไทย',
    value: 'th',
    image: ThaiIcon,
  },
]

const CountryDropdown = ({ onChange }) => {
  const defaultValue = localStorage.getItem('lang') || 'en'
  const [icon, setIcon] = useState(options?.find(option => option.value === defaultValue)?.image)

  const handleOnChange = (e, { value }) => {
    e.persist()
    const { image } = options?.find(option => option.value === value)
    setIcon(image)
    onChange(value)
  }

  return (
    <CustomDropListStyle>
      <img alt="" className="flag-img" src={icon} />
      <Dropdown
        fluid
        options={options}
        onChange={handleOnChange}
        defaultValue={defaultValue}
      />
    </CustomDropListStyle>
  )
}

CountryDropdown.propTypes = {
  onChange: PropTypes.func,
}

export default CountryDropdown
