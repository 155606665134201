import queryString from 'qs'
import * as R from 'ramda'
import numeral from 'numeral'
import 'numeral/locales/ja'
import moment from 'moment'
import bigInt from 'big-integer'

export const setLocal = (local) => {
  numeral.locale(local)
}

export const checkActivityType = (recentActivity) => {
  switch (recentActivity.type) {
    case 'deal':
      return !recentActivity.text ? 'Deal Created' : `${recentActivity.text}`
    case 'note':
      return !recentActivity.text ? 'Note Added' : `${recentActivity.text}`
    case 'task':
      return !recentActivity.text ? 'Task Added' : `${recentActivity.text}`
    case 'requestApproval':
      return !recentActivity.text ? 'REQUEST APPROVAL' : `${recentActivity.text}`

    default:
      return `${recentActivity.text}`
  }
}

export const isValidInput = (companyInput) => {
  if (companyInput.name === '') {
    return { name : '* Please enter company name !' }
  } else if (companyInput.postalCode && Number.isNaN(Number(companyInput.postalCode))) {
    return { postalCode : '* Postal code must be a number !' }
  } else if (companyInput.phones.length > 0 && Array.isArray(companyInput.phones)) {
    const errorMessagePhone = companyInput.phones.map((phone,index)=>{
        if(phone && Number.isNaN(Number(phone))) {
          return { phone: '* Phone number is not valid ! (Please fill the numeric only e.g. 021234567 or +6621234567)' }
        }else return  { phone : '' }
      }
    )
      return errorMessagePhone
  }
  return ''
}

export const roundFinancial = number => Math.round(number * 100) / 100

export const numberFormat = number => numeral(number).format('0,0')

export const numberDecimalFormat = number => numeral(number).format('0,0.00')

export const numberShortFormat = number => numeral(number).format('0.[00]a')

export const autoNumberFormat = number => numeral(number).format('0,0[.]00')

export const getSumValueFromDeal = (deals) => {
  let result = bigInt()
  result = deals.reduce((a, b) => a + b.value, 0)
  return autoNumberFormat(result)
}

export const commaToNumber = commaString => numeral(commaString).value()

export const sortByDate = key => (a, b) => {
  if(a[key] === b[key]) return b.id - a.id
  return new Date(b[key]) - new Date(a[key])}

export const formatExternalUrl = (url) => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }
  return `http://${url}`
}

export const filterKeyList = [
  'ownerId', 'labelsId', 'dealName', 'value', 'note', 'noteDate', 'estClosingDate', 'createDate',
  'closeDate', 'status', 'companyId', 'contactId',
]

export const haveDealFilter = (filterInput) => {
  let result = false
  R.forEachObjIndexed((value) => {
    result = result || (value && !R.isEmpty(value))
  }, R.pick(filterKeyList, filterInput))
  return result
}

const formatDateFilter = noteDate => (noteDate && !R.isEmpty(noteDate) ? {
  from: +moment(parseInt(noteDate.from, 10)) || undefined,
  to: +moment(parseInt(noteDate.to, 10)) || undefined,
} : {})

export const FILTER_SAVED_STATE = 'dealFilterSavedState'

export const DEAL_FILTER_INPUT = 'saleStagesFilterInput'

export const getSaleStagesFilterInputObject = (query, pipelineId) => {
  let result = null
  if (haveDealFilter(query)) {
    const filterInput = R.pick(filterKeyList, query)
    const {
      labelsId, ownerId, dealName, value, note, noteDate, estClosingDate, createDate, closeDate,
      status, companyId, contactId,
    } = filterInput
    result = {
      pipelineId,
      labelsId: labelsId ? labelsId.map(label => parseInt(label, 10)) : [],
      ownerId: ownerId ? ownerId.map(owner => parseInt(owner, 10)) : [],
      dealName,
      value: value && !R.isEmpty(value) ? {
        min: parseFloat(value.min),
        max: parseFloat(value.max),
      } : {},
      note,
      noteDate: formatDateFilter(noteDate),
      estClosingDate: formatDateFilter(estClosingDate),
      createDate: formatDateFilter(createDate),
      closeDate: formatDateFilter(closeDate),
      status,
      companyId: parseInt(companyId, 10) || undefined,
      contactId: parseInt(contactId, 10) || undefined,
    }
  }
  return result
}

export const getSaleStagesFilterInput = ({ pipelineId }) => {
  const localQuery = queryString.parse(localStorage.getItem(DEAL_FILTER_INPUT), {
    ignoreQueryPrefix: true,
  })
  const urlQuery = queryString.parse(window.location.search, { ignoreQueryPrefix: true })
  if (urlQuery.dealId) {
    return { redirect: `/deal/detail/${parseInt(urlQuery.dealId, 10)}${window.location.search}` }
  } else if (urlQuery.taskId) {
    return { redirect: `/tasks${window.location.search}` }
  } else if (!R.isEmpty(urlQuery)) {
    localStorage.setItem(DEAL_FILTER_INPUT, queryString.stringify(urlQuery))
    return {
      redirect: false,
      saleStagesFilterInput: getSaleStagesFilterInputObject(urlQuery, parseInt(pipelineId, 10)),
    }
  } else if (!R.isEmpty(localQuery)) {
    return {
      redirect: `${pipelineId}?${localStorage.getItem(DEAL_FILTER_INPUT)}`,
      saleStagesFilterInput: null,
    }
  }
  return { redirect: false, saleStagesFilterInput: null }
}

export const dateFormat = date => moment(date).format('D MMMM YYYY')

export const isImageURL = url => url && url.match(/\.(jpeg|jpg|gif|png)$/) != null

export const getLastSegmentURL = url => url && url.split('/').pop()

export const clearStorage = () => {
  const appVersion = process.env.REACT_APP_VERSION
  window.localStorage.clear()
  window.sessionStorage.clear()
  window.localStorage.setItem('appVersion', appVersion)
}

export const filterPipelinePermission = (pipelines, currentUserId) =>
  pipelines.filter(pipeline =>
    pipeline.members.find(member => (member.role !== 'user') && (member.user.id === currentUserId)))

export const removeWhiteSpace = (string) =>{
  if(string !== undefined) {
    return string.trim()
  }
}
