import React from 'react'

import FreeTrailExpried from '../components/freeTrailExpried'

const FreeTrailExpriedPage = () => (
  <div>
    <FreeTrailExpried />
  </div>
)

export default FreeTrailExpriedPage
