import styled from 'styled-components'
import {
  withStyles,
  TextField,
  Button,
} from '@material-ui/core'
import NewGoogleLoginWithGql from '../common/googleLogin'
import LineLoginWithGql from '../oauth/line/LineLogin'

export const SignInContainer = styled.div`
.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.font-signIn {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  color: #495e72;
}

.signIn-container {
  display: flex;
  margin-bottom: 30px;
  margin-top: 20px;
}

.input-container {
  display: flex;
  width: 100%;
  min-width: 400px;
  padding-left: 300px;
  padding-right: 300px;
  margin-bottom: 20px;
}

.input-style {
  border-radius: 4px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
  height: 40px;
  width: 100%;
  padding-left: 5px;
  min-width: 400px;
}

.input-font {
  font-size: 13px;
  font-weight: 300;
}

.font-signIn {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
.font-signIn-botton {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
}

.large {
  width: 250px;
  height: 40px;
}

.text {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.23;
}

.font-alert {
  width: 500px;
  justify-content: center;
  align-items: center;
}

.font-warning {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.23;
  text-align: justify;
  color: #a31e1e;
}
.row-alert {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.font-line {
  padding-right: 60px;
  font-weight: normal;
  font-size: 15px;
}
.row-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.line-logo {
  width: 35px;
  height: 35px;
}
.margin-left-logo {
  margin-left: 20px;
}
.margin-top-forgot-password {
  display: flex;
  margin-top: 25px;
}
.margin-right-forgot-password {
  margin-right: 70px;
}
.center-row {
  display: flex;
  flex-direction: row;
  margin-left: 70px;
  justify-content: center;
  align-items: center;
}
.margin-horizontal {
  display: flex;
  justify-content: center;
}
.logo-warning {
  width: 18;
  height: 15;
}
.margin-left-warning {
  margin-left: 5px;
}
.margin-right-warning {
  margin-right: 5px;
}
.margin-right-notNum {
  margin-right: 5px;
}
.margin-left-signUp {
  margin-left: 5px;
}
.absolute-top {
  z-index: 1;
  position: absolute;
  top: 35px;
  margin: auto;
  left: 0;
  right: 0;
}
.absolute-top-right {
  z-index: 1;
  position: absolute;
  top: 35px;
  right: 50px;
}
.top {
  padding-top: 60px;
}
h6.signupDeviderdashed {
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #98a5ba;
  margin: 20px auto;
  font-weight: 400;
  font-size: 13px;
}

h6.signupDeviderdashed:before,
h6.signupDeviderdashed:after {
  content: '';
  border-top: 1px solid #e2e8f2;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}

h6.signupDeviderdashed:after {
  margin: 0 0 0 20px;
}

.google-button-large {
  height: 40px;
  width: 250px;
  max-width: 360px;
  background: white;
  color: #666666;
  border-radius: 4px;
  white-space: nowrap;
  border: 1px solid #bebbbb;
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
  cursor: pointer;
  margin-bottom: 20px;
}

.google-button-large:focus,
.google-button-large:hover {
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
}

.google-button-large:active {
  background-color: #e5e5e5;
  box-shadow: none;
  transition-duration: 10ms;
}

.google-button__icon-large {
  display: initial;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 5px;
  height: 5px;
  box-sizing: border-box;
}

.google-button__icon-large svg {
  height: 40%;
  display: inline-block;
}

.google-button__text-large {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-weight: normal;
  font-size: 15px;
}
`
export const CustomTextField = withStyles(
  {
    root: {
      width: 400,
      margin: '10px 0',
      '& .MuiFormLabel-root': {
        color: '#98a5ba',
      },
      '& .MuiFilledInput-root': {
        backgroundColor: '#f0f3f7',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: '1px solid #e2e8f2'
      },
      '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: 0,
      },
      '&  .MuiFilledInput-input': {
        padding: '14px 12px 14px',
        '&::placeholder': {
          color: '#98a5ba',
          opacity: 1,
        }
      }
    },
  }
)(TextField)

export const CustomButton = withStyles(
  {
    root: {
      margin: '10px 0',
      height: 48,
      width: 400,
      '& .MuiButton-label': {
        fontSize: 16,
        fontWeight: 'bold',
        textTransform: 'none',
      }
    },
  }
)(Button)

export const GoogleIconBox = styled.span`
  display: initial;
  vertical-align: middle;
  width: 48px;
  height: 46px;
  box-sizing: border-box;
  svg {
    height: 100%;
    display: inline-block;
  }
`

export const ButtonText = styled.span`
  display: inline-block;
  vertical-align: middle;
  padding: 0 59px;
  font-weight: normal;
  font-size: 18px;
`
const ButtonGoogle = `
  height: 48px;
  width: 100%;
  max-width: 400px;
  background: white;
  color: #495e72;
  border-radius: 4px;
  white-space: nowrap;
  border: 1px solid #bebbbb;
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
  cursor: pointer;
  margin-bottom: 20px;
  &:focus,
  &:hover {
    border: 1px solid #495e72;
    hr{
      background-color: #495e72;
    }
  }
  & hr{
    background-color: #bebbbb;
  }
  &:active {
    background-color: #e5e5e5;
    box-shadow: none;
    transition-duration: 10ms;
  }
`

export const ButtonGoogleLogin = styled(NewGoogleLoginWithGql)`
  ${ButtonGoogle}
`
export const ButtonLine = styled(LineLoginWithGql)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  ${ButtonGoogle}
`