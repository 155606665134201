import React from 'react'
import { Query } from 'react-apollo'
import PropTypes from 'prop-types'
import Avatar from './Avatar'
import { queryAvatarDetail } from '../../../gql/user.gql'

const AvatarWithGql = (props) => {
  if (!props.userId) {
    return <Avatar {...props} />
  }
  return (
    <Query
      query={queryAvatarDetail}
      variables={{ userInput: { id: props.userId } }}
      fetchPolicy="cache-first"
    >
      {({ data: { user }, loading }) => {
        if (loading) return null
        return <Avatar {...props} src={user.imageUrl} name={user.name} />
      }}
    </Query>
  )
}

AvatarWithGql.propTypes = {
  ...Avatar.propTypes,
  userId: PropTypes.number,
}
AvatarWithGql.defaultProps = {
  userId: null,
}

export default AvatarWithGql
