import React from 'react'
import queryString from 'qs'
import SignupForm from '../components/signUp/SignupForm'
import PageWrapper from '../styles/pageWrapper'

const SignupPage = ({ match }) => {
  const query = queryString.parse(window.location.search, { ignoreQueryPrefix: true })

  /* if not have Line and Google parameters that returned
  in the callback URL query string,
  * remove the country localStorage
  */

  if (!query.state) {
    localStorage.removeItem('country')
  }

  return (
    <PageWrapper>
      <SignupForm inviteToken={match.params.inviteToken} />
    </PageWrapper>
  )
}

export default SignupPage
