import styled from 'styled-components'

export const SmallLinkWrapper = styled.div`
  color: #495e72;
  font-size: 13px;
  margin-top: 20px;
  cursor: pointer;
`

export default SmallLinkWrapper
