import React from 'react'
import { Query } from 'react-apollo'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { getLineLoginUrl } from '../../../../gql/login.gql'
import OAuthLogin from '../../../common/oAuthLogin/OAuthLogin'

const LineLoginWithGql = ({ action, lang, inviteToken, organizationId, redirectWithApiKey, ...props }) => (
  <Query
    query={getLineLoginUrl}
    variables={{ state: JSON.stringify({ action, lang, inviteToken, organizationId, redirectWithApiKey, provider: 'line' }) }}
  >
    {({ data }) => (
      <OAuthLogin
        {...props}
        oAuthUrl={data && data.getLineOAuthUrl}
        provider="line"
      />
    )}
  </Query>
)
LineLoginWithGql.propTypes = {
  ...R.omit(['oAuthUrl', 'provider'], OAuthLogin.propTypes),
  action: PropTypes.string.isRequired,
  lang: PropTypes.string,
  inviteToken: PropTypes.string,
  redirectWithApiKey: PropTypes.shape({
    type: PropTypes.string,
    ref: PropTypes.string,
    redirectUrl: PropTypes.string,
  }),
}
LineLoginWithGql.defaultProps = {
  lang: 'en',
  inviteToken: undefined,
  redirectWithApiKey: {},
}
export default LineLoginWithGql
