import gql from 'graphql-tag'

export const forgetPassword = gql`
mutation forgetPassword($email:String){
  forgetPassword(email:$email){
    email
    error
  }
}
`
export const resetPassword = gql`
  mutation resetPassword($resetPasswordInput:ResetPasswordInput) {
    resetPassword(resetPasswordInput:$resetPasswordInput){
      email
      error
    }
  }
`
export const getTokenStatus = gql`
query getTokenStatus($token:String){
  getTokenStatus(token:$token){
    status
  }
}
`
export default {}
