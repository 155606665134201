import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Alert } from 'reactstrap'
import { Redirect } from 'react-router'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { translate } from 'react-multi-lang'
import Cookies from 'js-cookie'
import queryString from 'qs'
import GoogleIcon from '../../common/icon/GoogleIcon'
import { validationSchema } from './ValidationForm'
import {
  ButtonGoogleLogin,
  ButtonLine,
  ColumnCenter,
  Divider,
  FooterText,
  ButtonText,
  GoogleIconBox,
  MarginLeft,
  MarginRight,
  Row,
  SubTitle,
  Text,
  CountryDropdownContainer,
  CustomTextField,
  CustomButton,
  CustomFormHelperText,
  CustomFormControl,
  TermAndPrivacyPolicyContainer,
} from './StylesSignupForm'
import { ReactComponent as LineIcon } from '../../../assets/image/line.svg'
import { tracker } from '../../../analytics'
import { getGoogleProfileFromCode, getLineProfileFromCode } from '../../../gql/login.gql'
import CountryDropdown from '../../common/countryDropdown'
import {
  Divider as DividerMui,
  Grid,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import wisibleLogoImg from '../../../assets/image/wisible_logo.svg'
import { ReactComponent as EyeIcon } from '../../../assets/image/eye.svg'
import { ReactComponent as CloseEyeIcon } from '../../../assets/image/close-eye.svg'
import { ReactComponent as EmailIcon } from '../../../assets/image/email.svg'
import { ReactComponent as PasswordIcon } from '../../../assets/image/password.svg'
import emailMask from 'email-mask';
class SignupForm extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
    loading: false,
    redirect: false,
    signupEmail: null,
    error: null,
    errorStatus: false,
    requireEmailPermission: false,
    token: null,
    firstPromoterID: Cookies.get('_fprom_track') ? Cookies.get('_fprom_track') : null,
    agreeTermAndPrivacyPolicy: false,
  }

  componentDidMount() {
    const { error } = queryString.parse(window.location.search, { ignoreQueryPrefix: true })
    if (error === 'email-not-provided') this.setState({ requireEmailPermission: true })
    if (error === 'existed-email') this.setState({ error: 'Email is already existed', })
  }

  async signUpEmailMutation({ email, password }) {
    const userInput = { email: email.toLowerCase(), password }
    if (this.props.inviteToken && this.props.organizationId) {
      userInput.organizationId = this.props.organizationId
    }
    return this.props.signupEmailMutation({
      variables: {
        createUserInput: { userInput },
        inviteToken: this.props.inviteToken,
        firstPromoterID: this.state.firstPromoterID,
      },
    })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault()
  };


  signUpEmail = async ({ email, password }) => {
    this.setState({
      loading: true,
      error: null,
    })
    const result = await this.signUpEmailMutation({ email, password })
    const action = result.data.signupWithEmail?.action
    const token = result.data.signupWithEmail?.token
    if (action === 'created' || action === 'invited') {
      this.setState({
        email,
        token,
        redirect: !!token,
        loading: false,
      })
      localStorage.setItem('signUpMethod', 'email')
      tracker.track('Signup (Start)', {
        category: 'App Activity',
        email: emailMask(email),
        registrationMethod: 'Email',
        invite: false,
        date: new Date(),
      })
    } else {
      const { loginMutation } = this.props
      //that func is use for redirect !!!
      const loginResult = await loginMutation({
        variables: {
          loginInput: {
            email,
            password,
          },
        },
      })
      if (!loginResult.errors) {
        if (!loginResult.data.login.message) {
          localStorage.setItem('token', loginResult.data.login.token)
          localStorage.setItem('refreshToken', loginResult.data.login.refreshToken)
          tracker.track('Signin', {
            category: 'App Activity',
            email: emailMask(loginResult.data.login.user.email),
          })
          window.location = '/selectOrganization'
        } else {
          this.setState({
            email,
            loading: false,
            error: 'Email is already existed',
          })
        }
      }
    }
  }

  responseGoogle = async (response, passedState) => {
    if (response.error) {
      this.setState({ error: response.errorDesc || response.error })
    } else {
      const { query } = this.props
      const result = await query({
        query: getGoogleProfileFromCode,
        variables: { code: response, inviteToken: passedState.inviteToken, organizationId: passedState.organizationId },
      })
      this.setState({ redirect: true, token: result.data.getGoogleProfileFromCode })
      localStorage.setItem('signUpMethod', 'google')
      tracker.track('Signup (Start)', {
        category: 'App Activity',
        email: emailMask(JSON.stringify(response.profileObj)),
        registrationMethod: 'Google',
        invite: false,
        date: new Date(),
      })
    }
  }

  responseLine = async (response, passedState) => {
    if (response.error) {
      this.setState({ error: response.errorDesc || response.error })
    } else {
      const { query } = this.props
      const result = await query({
        query: getLineProfileFromCode,
        variables: { code: response, inviteToken: passedState.inviteToken, organizationId: passedState.organizationId },
      })
      this.setState({ redirect: true, token: result.data.getLineProfileFromCode })
      localStorage.setItem('signUpMethod', 'line')
      tracker.track('Signup (Start)', {
        category: 'App Activity',
        email: emailMask(JSON.stringify(response.profileObj)),
        registrationMethod: 'Line',
        invite: false,
        date: new Date(),
      })
    }
  }

  render() {
    const {
      redirect, loading, error, token, requireEmailPermission,
    } = this.state
    if (redirect) {
      return <Redirect to={`/signupFull/${token}`} />
    }
    const { inviteToken, organizationId, t } = this.props
    return (
      <ColumnCenter>
        {isMobile && (
          <Alert color="danger">
            {/* TH_LANG เพื่อการใช้งานอย่างมีประสิทธิภาพสูงสุด กรุณาเข้าใช้งานผ่านคอมพิวเตอร์ค่ะ ขออภัยในความไม่สะดวก */}
            Suggestion: Please use desktop for the best viewing experience.
          </Alert>
        )}
        {requireEmailPermission && (
          <Alert color="danger">
            Email is required to sign up.
          </Alert>
        )}
        <CountryDropdownContainer>
          <CountryDropdown onChange={(value) => {
            window.location.href = `?lang=${value}`
          }}
          />
        </CountryDropdownContainer>
        <img src={wisibleLogoImg} />
        <SubTitle>{t('SignUp.SignUpToWisible')}</SubTitle>
        <Text>{t('SignUp.NoCreditCardRequired')}</Text>
        <ButtonGoogleLogin
          action="signup"
          inviteToken={inviteToken}
          organizationId={organizationId}
          onSuccess={this.responseGoogle}
          onError={this.responseGoogle}
        >
          <Grid container alignItems="center" >
            <GoogleIconBox>
              <GoogleIcon />
            </GoogleIconBox>
            <DividerMui orientation="vertical" flexItem />
            <ButtonText>{t('SignUp.SignUpWithGoogle')}</ButtonText>
          </Grid>
        </ButtonGoogleLogin>
        <ButtonLine
          action="signup"
          inviteToken={inviteToken}
          organizationId={organizationId}
          onSuccess={this.responseLine}
          onError={this.responseLine}
        >
          <Grid container alignItems="center" >
            <GoogleIconBox>
              <LineIcon />
            </GoogleIconBox>
            <DividerMui orientation="vertical" flexItem />
            <ButtonText>{t('SignUp.SignUpWithLine')}</ButtonText>
          </Grid>

        </ButtonLine>
        <Divider>{t('SignUp.Or')}</Divider>
        <Formik
          enableReinitialize
          initialValues={this.state}
          validationSchema={validationSchema(t)}
          onSubmit={(values, actions) => {
            actions.resetForm()
            this.signUpEmail(values)
          }}
          initialErrors={{ email: error === 'EMAIL_ALREADY_EXISTS' ? t('errors.EMAIL_ALREADY_EXISTS') : error }}
        >
          {({
            errors,
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <>
              <CustomTextField
                type="email"
                placeholder={t('SignUp.EmailPlaceholder')}
                value={values.email}
                onChange={handleChange('email')}
                error={!!errors.email}
                helperText={errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <CustomTextField
                onCopy={(e)=>{
                  e.preventDefault()
                  return false}}
                type={values.showPassword ? 'text' : 'password'}
                placeholder={t('SignUp.PasswordPlaceholder')}
                value={values.password}
                onChange={handleChange('password')}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <FormControlLabel
                        style={{margin: '0'}}
                        control={
                          <Checkbox
                            checked={values.showPassword}
                            // error={!!errors.agreeTermAndPrivacyPolicy}
                            onChange={handleChange('showPassword')}
                            hidden={true}
                          />}
                        label={values.showPassword ? <EyeIcon/> : <CloseEyeIcon/>}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <CustomTextField
                onCopy={(e)=>{
                  e.preventDefault()
                  return false}}
                type={values.showPassword ? 'text' : 'password'}
                placeholder={t('SignUp.ConfirmPasswordPlaceholder')}
                value={values.confirmPassword}
                onChange={handleChange('confirmPassword')}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <FormControlLabel
                        style={{margin: '0'}}
                        control={
                          <Checkbox
                            checked={values.showPassword}
                            // error={!!errors.agreeTermAndPrivacyPolicy}
                            onChange={handleChange('showPassword')}
                            hidden={true}
                          />}
                        label={values.showPassword ? <EyeIcon/> : <CloseEyeIcon/>}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <CustomFormControl
                error={!!errors.agreeTermAndPrivacyPolicy}
                fullWidth
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.agreeTermAndPrivacyPolicy}
                      // error={!!errors.agreeTermAndPrivacyPolicy}
                      onChange={handleChange('agreeTermAndPrivacyPolicy')}
                      helperText={errors.agreeTermAndPrivacyPolicy}
                    />}
                  label={
                    <TermAndPrivacyPolicyContainer>
                      <span>{t('SignUp.AgreeTo')}</span>
                      <Link to={{ pathname: "https://www.wisible.com/th/terms/" }} target="_blank"  >{t('SignUp.Terms')}</Link>
                      <span>{t('SignUp.And')}</span>
                      <Link to={{ pathname: "https://www.wisible.com/th/privacy/" }} target="_blank"  >{t('SignUp.PrivacyPolicy')}</Link>
                    </TermAndPrivacyPolicyContainer>}
                />
                {!!errors.agreeTermAndPrivacyPolicy && <CustomFormHelperText >{errors.agreeTermAndPrivacyPolicy}</CustomFormHelperText>}
              </CustomFormControl>
              <CustomButton
                onClick={() => handleSubmit()}
                disabled={loading || !values.email || !values.agreeTermAndPrivacyPolicy}
                color="primary"
              >
                {t('SignUp.SignUpButton')}
              </CustomButton>

            </>
          )}
        </Formik>
        {
          !inviteToken &&
          <Row>
            <MarginRight>
              <FooterText>{t('SignUp.AlreadyAMember')}</FooterText>
            </MarginRight>
            <MarginLeft>
              <Link to="/">
                <FooterText>{t('SignUp.SignInNow')}</FooterText>
              </Link>
            </MarginLeft>
          </Row>
        }
      </ColumnCenter>
    )
  }
}

SignupForm.propTypes = {
  loginMutation: PropTypes.func.isRequired,
  signupEmailMutation: PropTypes.func.isRequired,
  inviteToken: PropTypes.string,
  query: PropTypes.func.isRequired,
  organizationId: PropTypes.number,
}

SignupForm.defaultProps = {
  inviteToken: undefined,
  organizationId: undefined,
}

export default translate(SignupForm)
