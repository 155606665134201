import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { NoneOrganizationBody, CreateOrgButton } from './styles'
import WizBoyImage from '../../assets/image/noneOrganization/wizboy.png'

const NoneOrganization = () => {
    const [redirect, setRedirect] = useState(false)
    const onClickButton = () => {
        setRedirect("/createOrganization")
    }
    if (redirect) return <Redirect to={{ pathname: redirect, state: { hideSelectOrganizationText: true } }} />
    return (
        <NoneOrganizationBody>
            <>
                <h1 className="org-select-title">You have no organization at the moment.<br />Please Create new organization.</h1>
            </>
            <CreateOrgButton onClick={onClickButton}>+ New Organization</CreateOrgButton>
            <img className="wizboy-image" src={WizBoyImage} alt="Wizboy" />
        </NoneOrganizationBody>
    )
}


export default NoneOrganization
