import React from 'react'

import ResetPassWord from '../components/resetPassword'

const ResetPassWordPage = ({ match: { params } }) => (
  <div>
    <ResetPassWord token={params.token} />
  </div>
)

export default ResetPassWordPage
