import jwt from 'jsonwebtoken'

import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { TokenRefreshLink } from 'apollo-link-token-refresh'

import appConfig from '../config/app.config'
import { clearStorage } from '../util'

const getEndpoint = () => `${process.env.REACT_APP_REST_ENDPOINT}/refreshToken`

export const getRefreshToken = () => localStorage.getItem('refreshToken')

export const getAccessToken = () => localStorage.getItem('token')

export const setToken = token => localStorage.setItem('token', token)

export const setRefreshToken = refreshToken => localStorage.setItem('refreshToken', refreshToken)

export const isOrganizationToken = () => {
  const token = jwt.decode(getAccessToken())
  return !!token?.organizationId
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'UNION',
          name: 'EmailFolderOrLabel',
          possibleTypes: [
            {
              name: 'EmailFolder',
            },
            {
              name: 'EmailLabel',
            },
          ],
        },
      ],
    },
  },
})

const cache = new InMemoryCache({
  fragmentMatcher,
})

const graphqlWs = new SubscriptionClient(process.env.REACT_APP_GRAPHQL_SUBSCRIPTION, {
  reconnect: true,
})
graphqlWs.use([
  {
    applyMiddleware(operationOptions, next) {
      const token = getAccessToken()
      // eslint-disable-next-line no-param-reassign
      operationOptions.authorization = token ? `Bearer ${token}` : ''
      next()
    },
  },
])

const linkErr = onError(({ graphQLErrors, networkError }) => {
  const redirectPaths = ['/deal', '/networkError', '/'] // order by priority desc
  let redirectTo = -1
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'TOKEN_FAIL' || message === 'TOKEN_IS_EXPIRED') {
        clearStorage()
        redirectTo = Math.max(redirectTo, redirectPaths.indexOf('/'))
      } else if (message === 'PERMISSION_DENIED') {
        redirectTo = Math.max(redirectTo, redirectPaths.indexOf('/deal'))
      }
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    redirectTo = Math.max(redirectTo, redirectPaths.indexOf('/networkError'))
  }
  if (redirectTo !== -1 && window.location.pathname !== redirectPaths[redirectTo]) {
    if (([
      'staging-standard',
      'master-standard',
    ].includes(process.env.REACT_APP_ENV_NAME) || process.env.NODE_ENV === 'development')
      && redirectTo === redirectPaths.indexOf('/networkError')) {
      return
    }
    window.location.href = redirectPaths[redirectTo] + window.location.search
  }
})

const isTokenExpired = () => {
  const token = jwt.decode(getAccessToken())
  return token && (Date.now() + appConfig.MAXIMUM_PING) / 1000 > token.exp
}

const linkJWT = new TokenRefreshLink({
  accessTokenField: 'token',
  isTokenValidOrUndefined: () => typeof getAccessToken() !== 'string' || !isTokenExpired(),
  fetchAccessToken: () => fetch(getEndpoint(), {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'refresh-token': getRefreshToken(),
    },
  }),
  handleResponse: (operation, accessTokenField) => async (response) => {
    const { token, refreshToken } = await response.json()
    setToken(token)
    setRefreshToken(refreshToken)
    return {
      token,
      refreshToken,
    }
  },
  handleError: (err) => {
    clearStorage()
    window.location = '/'
  },
})

const linkWs = new WebSocketLink(graphqlWs)
const linkUpload = createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT })
const linkHttp = ApolloLink.from([
  linkErr,
  linkUpload,
  createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT }),
])
const linkAuth = setContext((_, { headers }) => {
  const token = getAccessToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const link = ApolloLink.split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  linkWs,
  ApolloLink.from([
    linkJWT,
    linkAuth.concat(linkHttp),
  ]),
)

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  link,
  cache,
  defaultOptions,
  connectToDevTools: true,
})

export default client
