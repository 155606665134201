import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from 'reactstrap'
import moment from 'moment'
import './ForgetPassword.css'
import wisibleLogo from '../../assets/image/loginSignup/wisible-logo-mono.svg'
import emailSent from '../../assets/image/loginSignup/aw-email-sent.png'
import Countdown from '../common/Countdown'
import {
  Button,
  TextField
} from './style'
class SignupForm extends Component {
  state = {
    email: null,
    loading: false,
    signupEmail: null,
    error: null,
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSendEmail = async (e) => {
    e.preventDefault()
    const result = await this.props.sendForgetPassword({ variables: { email: this.state.email } })
    const { error } = result.data.forgetPassword
    if (error === 'ไม่มีอีเมลนี้ในระบบ') {
      this.setState({ error })
    } else {
      this.setState({ signupEmail: this.state.email, error })
    }
  }
  renderCountdown = (time) => {
    if (time <= 0) {
      return (
        <button
          onClick={e => this.handleSendEmail(e)}
          className="email-resent-text"
        >
          {/* TH_LANG คลิกที่นี่เพื่อส่งอีเมลอีกครั้ง */}
          Click here to resend email

        </button>
      )
    }
    return <span>Please wait {moment(time).format('mm:ss')} minutes to resend email.  </span>
  }

  renderMessage = () => {
    const { loading, error, signupEmail } = this.state
    if (loading) return <div>Loading ...</div>
    if (error) {
      return error === 'invalid email' ?
        error
        :
        <Countdown
          date={Date.now() + Number(error)}
          renderer={this.renderCountdown}
        />
    }
    if (signupEmail) {
      return (
        <div className="confirm-sent">
          <p>
            {/* TH_LANG กรุณาตรวจสอบอีเมลของคุณเปลี่ยนรหัสผ่าน */}
            Please check your email to reset your password
            {/* <br /> ระบบได้ส่งอีเมลไปที่ {signupEmail} */}
            <br /> The system sent an email to {signupEmail}
          </p>
          {/* TH_LANG หากไม่ได้รับอีเมล กรุณาตรวจสอบที่ Spam Folder หรือ */}
          if not receiving an email, please check Spam folder or
          <br />
          <button
            onClick={this.handleSendEmail}
            className="email-resent-text"
          >
            {/* TH_LANG คลิกที่นี่เพื่อส่งอีเมลอีกครั้ง */}
            Click here to resend email
          </button>
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <Container>
        <Row>
          <Col sm={{ size: '6', offset: 3 }}>
            <Card className="signupCard">
              <CardBody className="text-center">
                {!this.state.signupEmail ? (
                  <div>
                    <img src={wisibleLogo} alt="Wisible" />
                    {/* TH_LANG <CardText>กรอกอีเมลเพื่อเปลี่ยนรหัสผ่านของคุณ</CardText> */}
                    <CardText>Please provide your email to reset your password</CardText>
                    <Form onSubmit={e => this.handleSendEmail(e)}>
                      <FormGroup row>
                        <Col>
                          <TextField
                            value={this.state.email}
                            fullWidth
                            autoFocus
                            type="email"
                            name="email"
                            onChange={(e) => {
                              this.handleInputChange(e)
                            }}
                            placeholder="email"
                          // TH_LANG placeholder="อีเมล"
                          />
                        </Col>
                      </FormGroup>
                      <div className="red-text">{this.state.error}</div>
                      <Button
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        {/* TH_LANG ส่งอีเมล */}
                        Send
                      </Button>
                    </Form>
                  </div>
                ) :
                  (<div>
                    <p>
                      <img src={wisibleLogo} alt="Wisible" />
                    </p>
                    <p>
                      <img
                        className="email-sent"
                        src={emailSent}
                        alt="Email sent"
                      />
                    </p>
                    {this.renderMessage()}
                  </div>
                  )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

SignupForm.propTypes = {
  sendForgetPassword: PropTypes.func.isRequired,
}

export default SignupForm
