export default {
  IS_CLEAR_LOCAL_STORAGE: true,
  LINE_BOT_FILE_END_POINT: process.env.REACT_APP_LINE_BOT_FILE_END_POINT,
  MAXIMUM_PING: 60 * 1000,
  AUTO_LINK_EMAIL_INTERVAL: 15 * 60 * 1000,
  DEFAULT_LANGUAGE: 'en',
  SUPPORTED_LANGUAGE: { th: {}, en: {}, id: {} },
  CURRENCY_TO_LOCALE: {
    JPY: 'ja',
  },
  help: {
    connectGmail: 'https://www.wisible.com/help/link-gmail-to-wisible-with-2-step-verification/',
    connectHotmail: 'https://www.wisible.com/help/link-hotmail-outlook-to-wisible-with-two-step-verification/',
  },
}
