import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { translate } from 'react-multi-lang'
import './Loading.css'
import './LoadingInline.css'
import './lds-hourglass.css'
import WizBoy from '../wizBoy'

class Loading extends PureComponent {
  startTime = 0 // ms

  timer = null

  constructor(props) {
    super(props)
    this.state = {
      play: false,
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  start = () => {
    this.setState({ play: true }, () => {
      this.startTime = (+new Date())
    })
  }

  stop = () => {
    this.setState({ play: false }, () => {
      this.startTime = 0
    })
  }

  render() {
    clearTimeout(this.timer)
    const {
      show, backdrop, minDuration, inline, delay, t, children,
    } = this.props
    if (show) {
      this.timer = setTimeout(this.start, delay)
    } else {
      const now = (+new Date())
      const playDuration = now - this.startTime
      if (playDuration < minDuration) {
        this.timer = setTimeout(this.stop, minDuration - playDuration)
      } else {
        this.stop()
      }
    }
    const { play } = this.state
    if (inline) {
      return play && (
        <div className="loading-inline" />
      )
    }
    return (
      <div className="loading-parent">
        {children}
        {play && (
          <div className="loading">
            {backdrop && (
              <div className="loading-backdrop" />
            )}
            <div className="loading-icon">
              <WizBoy />
              <h2>
                {`"${t('Loading.PleaseWait')}"`}
              </h2>
            </div>
          </div>
        )}
      </div>
    )
  }
}

Loading.propTypes = {
  inline: PropTypes.bool,
  show: PropTypes.bool,
  backdrop: PropTypes.bool,
  children: PropTypes.node,
  minDuration: PropTypes.number,
  delay: PropTypes.number,
  t: PropTypes.func.isRequired,
}

Loading.defaultProps = {
  inline: false,
  show: false,
  backdrop: true,
  children: null,
  minDuration: 400, // ms
  delay: 300, // ms
}

export default translate(Loading)
