import React from 'react'

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.6" height="21.6" viewBox="0 0 21.6 21.6">
        <path d="M19.368 9.573a12.41 12.41 0 0 0-.2-2.209H9v4.178h5.812a4.968 4.968 0 0 1-2.155 3.26v2.71h3.49a10.533 10.533 0 0 0 3.22-7.938z" transform="translate(1.8 1.473)" fillRule='evenodd' fill='#4285f4' />
        <path data-name="Shape" d="M10.609 19.458a10.311 10.311 0 0 0 7.148-2.617l-3.49-2.71a6.516 6.516 0 0 1-9.7-3.422H.957v2.8a10.8 10.8 0 0 0 9.651 5.95z" transform="translate(.191 2.142)" fill='#34a853' fillRule='evenodd' />
        <path data-name="Shape" d="M4.757 11.86a6.385 6.385 0 0 1 0-4.1v-2.8H1.149a10.814 10.814 0 0 0 0 9.7l3.608-2.8z" transform="translate(0 .992)" fill='#fbbc05' fillRule='evenodd' />
        <path data-name="Shape" d="M10.609 4.3a5.834 5.834 0 0 1 4.129 1.615l3.1-3.1A10.379 10.379 0 0 0 10.609 0 10.8 10.8 0 0 0 .957 5.95l3.608 2.8a6.436 6.436 0 0 1 6.044-4.45z" transform="translate(.191)" fill='#ea4335' fillRule='evenodd' />
        <path data-name="Shape" d="M0 0h21.6v21.6H0z" fill='none' fillRule='evenodd' />
    </svg>

)
