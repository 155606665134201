import React from 'react'
import PropTypes from 'prop-types'

const interval = 1000

class Countdown extends React.PureComponent {
  state = {
    offset: 0,
  }

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ offset: this.state.offset - interval })
      , interval,
    )
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ offset: 0 })
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render = () => this.props.renderer(this.props.date - Date.now())
}

Countdown.propTypes = {
  date: PropTypes.number.isRequired,
  renderer: PropTypes.func.isRequired,
}

export default Countdown
