import gql from 'graphql-tag'

export const loginFields = `{
    token
    refreshToken
    message
    user {
      id
      name
      email
      phone
      imageUrl
    }
    isTwoFactorEnabled
  }
 `
export const loginMutation = gql`
  mutation login($loginInput : LoginInput){
    login(loginInput:$loginInput) ${loginFields}
  }
`

export const getTokenFromLine = gql`
  query getTokenFromLine($lineToken: LineToken){
    getTokenFromLine(lineToken: $lineToken) {
      token
      refreshToken
      message
      user {
        id
        name
        email
        phone
        organization{
          id
          name
        }
        imageUrl
      }
    }
  }
`

export const getTokenFromEmail = gql`
  query getTokenFromEmail($emailAuthInput: EmailAuthInput){
    getTokenFromEmail(emailAuthInput: $emailAuthInput) {
      token
      refreshToken
    }
  }
`

export const getLineLoginUrl = gql`
  query getLineLoginUrl($state: String!) {
    getLineOAuthUrl(state: $state)
  }
`

export const getGoogleOAuthUrl = gql`
  query getGoogleOAuthUrl($state: String!) {
    getGoogleOAuthUrl(state: $state)
  }
`

export const getGoogleProfileFromCode = gql`
  query getGoogleProfileFromCode($code: String!, $inviteToken: String, $organizationId: Int) {
    getGoogleProfileFromCode(code: $code, inviteToken: $inviteToken, organizationId: $organizationId)
  }
`

export const getLineProfileFromCode = gql`
  query getLineProfileFromCode($code: String!, $inviteToken: String, $organizationId: Int) {
    getLineProfileFromCode(code: $code, inviteToken: $inviteToken, organizationId: $organizationId)
  }
`

export default {
  loginMutation,
}
