import React from 'react'
import { Button } from '@material-ui/core'
import Logo from '../../../assets/image/error/logo.svg'
import SuspendedImg from '../../../assets/image/error/suspended.png'
import Avatar from '../../common/avatar'

const UserSuspended = ({ data }) => {
  return (
    <div className="user-suspended">
      <div className="container">
        <img src={Logo} alt="Logo" />
        <h2>
          You were suspended from the
          <br />
          {data.name} organization
        </h2>
        <p>Please contact your super admin</p>

        <div className="admins-wrapper flex">
          {data.admins.map((admin, index) => (
            <div key={index} className="flex item">
              <Avatar src={admin.imageUrl} name={admin.name} size={36} />
              <div>
                <h6>{admin.name}</h6>
                <p>{admin.email}</p>
              </div>
            </div>
          ))}
        </div>
        <img className="graphic" src={SuspendedImg} alt="Suspended" />
        <div className="actions">
          <Button
            variant="outlined"
            onClick={() => {
              window.location.href = '/selectOrganization?to=select'
            }}
          >
            Switch Organization
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              localStorage.removeItem('token')
              localStorage.removeItem('refreshToken')
              window.location.reload()
            }}
          >
            Log out
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UserSuspended
