import React from 'react'
import { Query } from 'react-apollo'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import { getGoogleOAuthUrl } from '../../../gql/login.gql'
import OAuthLogin from '../oAuthLogin/OAuthLogin'

const GoogleLoginWithGql = ({ action, lang, inviteToken, organizationId, redirectWithApiKey, ...props }) => (
  <Query
    query={getGoogleOAuthUrl}
    variables={{ state: JSON.stringify({ action, lang, inviteToken, organizationId, redirectWithApiKey, provider: 'google' }) }}
  >
    {({ data }) => (
      <OAuthLogin
        {...props}
        oAuthUrl={data && data.getGoogleOAuthUrl}
        provider="google"
      />
    )}
  </Query>
)
GoogleLoginWithGql.propTypes = {
  ...R.omit(['oAuthUrl', 'provider'], OAuthLogin.propTypes),
  action: PropTypes.string.isRequired,
  lang: PropTypes.string,
  inviteToken: PropTypes.string,
  redirectWithApiKey: PropTypes.shape({
    type: PropTypes.string,
    ref: PropTypes.string,
    redirectUrl: PropTypes.string,
  }),
}
GoogleLoginWithGql.defaultProps = {
  lang: 'en',
  inviteToken: undefined,
  redirectWithApiKey: {},
}
export default GoogleLoginWithGql
