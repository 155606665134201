import React from 'react'
import { Mutation } from 'react-apollo'

import ForgetPassword from './ForgetPassword'

import { forgetPassword } from '../../gql/forgetpassword.gql'

const forgetPasswordMutate = () => (
  <Mutation mutation={forgetPassword}>
    {
      sendForgetPassword => (
        <ForgetPassword sendForgetPassword={sendForgetPassword} />
      )
    }
  </Mutation>
)

export default forgetPasswordMutate
