import React from 'react'
import { Query } from 'react-apollo'
import { userOrganizationQuery } from '../../../gql/organization.gql'
import UserSuspended from './UserSuspended'

const UserSuspendedWithGql = ({ organizationId, setActive }) => (
  <Query
    query={userOrganizationQuery}
    variables={{
      organizationId,
    }}
  >
    {({ data, loading }) => {
      if (loading) return null
      return <UserSuspended data={data.userOrganization} setActive={setActive} />
    }}
  </Query>
)

export default UserSuspendedWithGql
