import React from 'react'
import queryString from 'qs'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'
import { LinearProgress } from '@material-ui/core'

const OAuthCallbackPage = ({ match: { params } }) => {
  const query = queryString.parse(window.location.search, { ignoreQueryPrefix: true })
  if (query.state) {
    const { action, callbackUrl } = JSON.parse(query.state)
    if (action === 'login') {
      return (
        <Redirect to={`/login${window.location.search}`} />
      )
    }
    if (action === 'signup') {
      return (
        <Redirect to={`/signup${window.location.search}`} />
      )
    }
    if (action === '2fa') {
      window.location.href = `${callbackUrl}${window.location.search}`
      return (
        <LinearProgress />
      )
    }
  }
  return (
    <Redirect to="/" />
  )
}

OAuthCallbackPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      provider: PropTypes.string,
    }),
  }).isRequired,
}

export default OAuthCallbackPage
