import styled from 'styled-components'

const DynamicInviteFormWrapper = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .footer {
    width: 500px; // related to upper container
    p {
      text-align: right;
      .action {
        cursor: pointer;
        color: #4183c4
      }
    }
  }
`

export default DynamicInviteFormWrapper
