import React from 'react'

import CreateOrganizationForm from '../components/linkOrganizationForm'
import PageWrapper from '../styles/pageWrapper'

const CreateOrganizationPage = ({ match: { params }, ...props }) => {
  const { token } = params
  return (
    <PageWrapper>
      <CreateOrganizationForm fromExistedUser token={token} {...props} />
    </PageWrapper>
  )
}

export default CreateOrganizationPage
