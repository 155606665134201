import React from 'react'
import { Query } from 'react-apollo'
import { organizationConfigQuery } from '../gql/organization.gql'
import { isOrganizationToken } from '../gql'

export default (Component, action) => class EnhancedComponent extends React.PureComponent {
  render() {
    if (isOrganizationToken()) {
      return (
        <Query query={organizationConfigQuery} variables={{ action }}>
          {
            ({ loading, data, refetch }) => (
              !loading
              && (
                <Component
                  {...this.props}
                  configRefetch={refetch}
                  {...{ [action]: data.organizationConfig && data.organizationConfig.value }}
                />
              )
            )
          }
        </Query>
      )
    }
    return (
      <Component
        {...this.props}
      />
    )
  }
}
