import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import errorIcon from '../../assets/image/error/error.png'
import './Errors.css'

class ErrorLinkExpired extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: null,
    }
  }

  render() {
    const { redirect } = this.state
    if (redirect) return <Redirect to={redirect} />
    return (
      <div className="error-pages">
        <div className="error-container">
          <img src={errorIcon} alt="Network Error" />
          <div className="error-text">
            <h2>AWWW… Too late</h2>
            <p>this link is expired</p>
          </div>
        </div>
      </div>
    )
  }
}

export default ErrorLinkExpired
