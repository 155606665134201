import { Query } from 'react-apollo'
import queryString from 'qs'
import React from 'react'
import PropTypes from 'prop-types'

import { getTokenFromEmail, getTokenFromLine } from '../../../gql/login.gql'

const getQuery = () => queryString.parse(window.location.search, { ignoreQueryPrefix: true })

const setToken = ({ token, refreshToken }) => {
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('refreshToken', refreshToken)
}

const LineFreshTokenWitGql = ({ profile, children }) => {
  let query
  let variables
  const { lineId, key2 } = getQuery()
  if (!lineId && !profile.userId && !key2) return children
  if (key2) {
    query = getTokenFromEmail
    variables = { emailAuthInput: { key2 } }
  } else if (lineId || (profile.userId)) {
    query = getTokenFromLine
    variables = { lineToken: { lineId: profile.userId || lineId } }
  }
  return (
    <Query query={query} variables={variables}>
      {
        ({ data, loading }) => {
          if (!loading && data) {
            const { token, refreshToken } = data.getTokenFromLine || data.getTokenFromEmail || {}
            if (token) {
              setToken({ token, refreshToken })
            }
            return children
          }
          return null
        }
      }
    </Query>
  )
}

LineFreshTokenWitGql.propTypes = {
  children: PropTypes.element.isRequired,
  profile: PropTypes.shape({
    userId: PropTypes.string,
  }),
}

LineFreshTokenWitGql.defaultProps = {
  profile: {},
}

export default LineFreshTokenWitGql
