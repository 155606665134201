import React from 'react'

import ForgetPassword from '../components/forgetPassword'

const forgetPasswordPage = () => (
  <div>
    <ForgetPassword />
  </div>
)

export default forgetPasswordPage
