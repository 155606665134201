import { ApolloProvider } from 'react-apollo'
import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.css'
import changeCase from 'change-case'
import { Helmet } from 'react-helmet'

import App from './App'
import client, { getAccessToken, getRefreshToken, setRefreshToken, setToken } from './gql'
import { unregister } from './registerServiceWorker'
import './index.css'
import appConfig from './config/app.config'
import { clearStorage } from './util'
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'

const setAppVersion = ({ clearLocalStorage }) => {
  const appVersion = process.env.REACT_APP_VERSION
  const currentAppVersion = window.localStorage.getItem('appVersion')
  if (currentAppVersion !== appVersion) {
    if (clearLocalStorage) {
      const refreshToken = getRefreshToken()
      const accessToken = getAccessToken()
      clearStorage()
      if (accessToken) setToken(accessToken)
      if (refreshToken) setRefreshToken(refreshToken)
    }
  }
}

setAppVersion({ clearLocalStorage: appConfig.IS_CLEAR_LOCAL_STORAGE })

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
          />
          {process.env.NODE_ENV !== 'production' ? (
            <title>{changeCase.upperCaseFirst(process.env.NODE_ENV)} Wisible</title>
          ) : null}
        </Helmet>
        <App />
      </>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)

unregister()
