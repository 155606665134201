import React from 'react'
import PropTypes from 'prop-types'

const RedirectIframe = () => {
  const [, to] = window.location.href.split('/redirectIframe/')
  return (
    <iframe
      src={`//${to}`}
      title="Wisible iframe"
      is="x-frame-bypass"
      loading="lazy"
      style={{ border: 0, width: '100vw', height: '100vh' }}
      allowFullScreen=""
      aria-hidden="false"
    />
  )
}

RedirectIframe.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      targetUrl: PropTypes.string,
    }),
  }).isRequired,
}

export default RedirectIframe
