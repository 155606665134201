import React, { useState } from 'react'
import OtpInput from 'react-otp-input'

import authenticatorImg from '../../../assets/image/authenticator.svg'
import { ReactComponent as WarningImg } from '../../../assets/image/warning_circle.svg'
import closeImg from '../../../assets/image/close-icon.png'

import { TwoFAContainer } from './style'
import { Divider, Button } from '@material-ui/core'
import { login2FA } from '../../../gql/twoFA.gql'
import client from '../../../gql'

const TwoFALogin = (props) => {
    const { userId, handleOnCancel, loginSuccess } = props

    const [verifyDigits, setVerifyDigits] = useState("")
    const [incorrectDigits, setIncorrectDigits] = useState(false)

    const handleChange = (digits) => setVerifyDigits(digits)

    const onSubmit = async () => {
        let response = await client.query({
            query: login2FA,
            variables: { twoFactorAuthenticationCode: verifyDigits, userId },
            fetchPolicy: 'network-only'
        })

        if (response.data.login2FA) {
            response.data['login'] = response.data['login2FA']
            loginSuccess(response)
        } else {
            setIncorrectDigits(true)
        }
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter' && verifyDigits.length === 6) {
            onSubmit()
        }
    }

    return (
        <TwoFAContainer>
            <div className='card stretched'>
                <div className='header'>
                    <span>Enter Code</span>
                    <img src={closeImg} onClick={handleOnCancel} />
                </div>
                <div className='banner'>
                    <img src={authenticatorImg} />
                </div>
                <div className='body' onKeyDown={handleEnter}>
                    <span>Enter 6-Digit authentication code generated by the <br /> Google Authenticator app</span>
                    <OtpInput
                        value={verifyDigits}
                        onChange={handleChange}
                        numInputs={6}
                        containerStyle='digits-container'
                        inputStyle='digits-input'
                        focusStyle='digits-focus'
                        isInputNum
                        shouldAutoFocus
                    />
                    {
                        incorrectDigits && <>
                            <WarningImg className='warning-icon' />
                            <span className='warning'>
                                The code is incorrect
                            </span>
                        </>
                    }
                </div>
                <div className='footer-divider' >
                    <Divider />
                </div>
                <div className='footer'>
                    <Button variant='text' color='primary' onClick={handleOnCancel}>Back</Button>
                    <Button className='submit-button' color='primary' disabled={verifyDigits.length < 6} onClick={onSubmit}>Verify</Button>
                </div>
            </div>
        </TwoFAContainer>
    )
}

export default TwoFALogin