import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import defaultAvatar from '../../../assets/avatar/default-avatar-new.svg'
import Wrapper from './style'

class Avatar extends PureComponent {
  getAvatarUrl = () => {
    const { src, name } = this.props
    if (src) {
      if (src.startsWith('blob:') || src.startsWith('http://') ||
        src.startsWith('https://')) return src
      return `${process.env.REACT_APP_UPLOADED_ENDPOINT}/${src}`
    }
    if (name) return this.nameAvatar()
    return defaultAvatar
  }

  nameAvatar = () => `${process.env.REACT_APP_HOSTNAME}/defaultImage/${this.props.size}/${this.props.name &&
    this.props.name.charAt(0)}`

  handleError = (e) => {
    const nameAvatar = this.nameAvatar()
    e.target.src = e.target.src === nameAvatar ? defaultAvatar : encodeURI(nameAvatar)
  }

  render() {
    const { size, sizeType, name, index } = this.props
    return (
      <Wrapper
        className="avatar-img"
        image={this.getAvatarUrl()}
        zIndex={index}
        sizeType={sizeType}
        size={size}
        alt="Avatar"
        onError={this.handleError}
        title={name}
      />
    )
  }
}

Avatar.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.number,
  sizeType: PropTypes.string,
  index: PropTypes.number,
}
Avatar.defaultProps = {
  name: null,
  src: null,
  size: 40,
  sizeType: 'px',
  index: 0,
}

export default Avatar
