import React from 'react'
import PropTypes from 'prop-types'
import SmallLinkWrapper from './style'

const SmallLink = ({ text, action }) => (
  <SmallLinkWrapper>
    <span onClick={action}>{text}</span>
  </SmallLinkWrapper>
)

SmallLink.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
}

export default SmallLink
